import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GrowlerModule} from '../growler/growler.module';
import {RouterModule} from '@angular/router';
import {ValidationService} from './form.service';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GrowlerModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [
    FormComponent
  ],
  exports: [
    FormComponent
  ],
  providers: [
    ValidationService
  ]
})
export class FormModule {
}
