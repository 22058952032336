import { Injectable } from '@angular/core';
import {BaseService, HttpService} from '../../shared';

@Injectable()
export class RoleService extends BaseService {

  constructor(protected http: HttpService) {
    super(http);
  }

  protected getBaseUrl(): string {
    return "/api/role";
  }

}
