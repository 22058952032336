import { Action } from 'rxjs/internal/scheduler/Action';
import { LinkCellComponent, CheckboxRenderer } from './core/grid/cell/cell-renderer';
import { listToStringFormatter, mapToString } from './shared/functions';
import { LoginState } from './shared/reducers';
import { USSDStore, UserProfileStore } from './app.reducers';
import $ from 'jquery';

  export interface AppAction extends Action<any> {
  payload?: any
}

export interface AppStore {
}

export interface IAppState {
  appStore?: AppStore;
  login?: LoginState;
  ussdStore?: USSDStore;
  userProfile?: UserProfileStore;
}

export const RelatedServiceItemKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/ussd-service-item', data.id]
    }
  },
  {
    headerName: 'Service',
    field: 'service',
    filterField: 'service.title',
    type: 'select',
    required: true,
    editable: false,
    options: [],
    cellRenderer: (item) => {
      return item.value ? item.value.title : item.value;
    },
    formatter: (item) => {
      return item.value ? item.value.id : item.value;
    }
  },
  {
    headerName: 'Titre',
    field: 'title',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Ordre',
    field: 'order',
    type: 'number',
    editable: true,
    required: true
  },
  {
    headerName: 'Colonne',
    field: 'targetField',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Tables cible',
    field: 'targetTables',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Params',
    field: 'params',
    editable: false,
    cellFormatter: (item) => {
      return mapToString(item.value)
    },
    formatter: (item) => {
      return mapToString(item.value)
    }
  },
  {
    headerName: 'Template',
    field: 'templateName',
    editable: true
  },
  {
    headerName: 'Fin',
    field: 'end',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is numeric',
    field: 'numeric',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Pattern',
    field: 'inputPattern',
    editable: true
  },
  {
    headerName: 'Is subscription',
    field: 'subscription',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is Payment needed',
    field: 'paymentNeeded',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is External',
    field: 'external',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Service Type',
    field: 'serviceType',
    editable: true
  }
];
export const RelatedDataItemKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/ussd-data-item', data.id]
    }
  },
  {
    headerName: 'Service Item',
    field: 'serviceItem',
    filterField: 'serviceItem.title',
    type: 'select',
    required: true,
    editable: false,
    options: [],
    cellRenderer: (item) => {
      return item.value ? item.value.title : item.value;
    },
    formatter: (item) => {
      return item.value ? item.value.id : item.value;
    }
  },
  {
    headerName: 'Title',
    field: 'title',
    editable: true,
    required: true
  },
  {
    headerName: 'Type',
    field: 'dataType',
    editable: true,
    required: false
  },
  {
    headerName: 'Valeur',
    field: 'content',
    editable: true,
    required: false
  },
  {
    headerName: "Code",
    field: "code",
    required: true,
    editable: false,
    readonly: (item) => {
      return item && item.id
    }
  },
  {
    headerName: 'Ordre',
    field: 'order',
    editable: true,
    type: 'number',
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Is Input',
    field: 'input',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is External',
    field: 'external',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Description',
    field: 'description',
    editable: true
  },
  {
    headerName: 'Template',
    field: 'templateName',
    editable: true
  }
]
export const DataItemKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/ussd-data-item', data.id]
    }
  },
  {
    headerName: 'Service Item',
    field: 'serviceItem',
    filterField: 'serviceItem.title',
    type: 'select',
    required: true,
    editable: false,
    options: [],
    cellRenderer: (item) => {
      return item.value ? item.value.title : item.value;
    },
    formatter: (item) => {
      return item.value ? item.value.id : item.value;
    }
  },
  {
    headerName: 'Title',
    field: 'title',
    editable: true,
    required: true,
    sortable: true
  },
  {
    headerName: 'Type',
    field: 'dataType',
    editable: true,
    required: false
  },
  {
    headerName: 'Valeur',
    field: 'content',
    editable: true,
    required: false
  },
  {
    headerName: "Code",
    field: "code",
    required: true,
    editable: false,
    readonly: (item) => {
      return item && item.id
    }
  },
  {
    headerName: 'Ordre',
    field: 'order',
    editable: true,
    type: 'number',
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Is Input',
    field: 'input',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is Link',
    field: 'link',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is Form',
    field: 'form',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is External',
    field: 'external',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    default: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Description',
    field: 'description',
    editable: true
  },
  {
    headerName: 'Template',
    field: 'templateName',
    editable: true
  },
  {
    headerName: 'Params',
    field: 'params',
    editable: false,
    cellFormatter: (item) => {
      return mapToString(item.value)
    },
    formatter: (item) => {
      return mapToString(item.value)
    }
  },
  {
    headerName: 'Black List Service Items',
    field: 'blackListServiceItems',
    editable: false,
    readonly: true,
    cellFormatter: (item) => {
      return listToStringFormatter(item.value)
    },
    formatter: (item) => {
      return listToStringFormatter(item.value)
    }
  },
  {
    headerName: 'Black List Data Items',
    field: 'blackListDataItems',
    editable: false,
    readonly: true,
    cellFormatter: (item) => {
      return listToStringFormatter(item.value)
    },
    formatter: (item) => {
      return listToStringFormatter(item.value)
    },
    hide: false
  },
  {
    headerName: 'Black Listed Service Items',
    field: 'excludedServiceItem',
    hide: true,
    type: 'relatedList',
    dataSource: [],
    dataSourceSelected: [],
    keys: RelatedServiceItemKeys,
    readonly: true,
    hideOnEdit: true,
    enableActions: false,
    enableAction: true,
    baseUrl: '/ussd-service-item',
    onAdd: function(event, $this) {
      $this.value.blackListServiceItems.push(event.item.id);
      $this.postLoad();
      $this.initValue($this.value, $this.view);
      console.log(event, $this);
    },
    onRemove: function(event, $this) {
      $this.value.blackListServiceItems = $this.value.blackListServiceItems.filter(e => e != event.item.id);
      $this.postLoad();
      $this.initValue($this.value, $this.view);
      console.log(event, $this);
    }
  },
  {
    headerName: 'Black Listed Data Items',
    field: 'excludedDataItem',
    hide: true,
    type: 'relatedList',
    dataSource: [],
    dataSourceSelected: [],
    keys: RelatedDataItemKeys,
    readonly: true,
    hideOnEdit: true,
    enableActions: false,
    enableAction: true,
    baseUrl: '/ussd-data-item',
    onAdd: function(event, $this) {
      $this.value.blackListDataItems.push(event.item.id);
      $this.postLoad();
      $this.initValue($this.value, $this.view);
      console.log(event, $this);
    },
    onRemove: function(event, $this) {
      $this.value.blackListDataItems = $this.value.blackListDataItems.filter(e => e != event.item.id);
      $this.postLoad();
      $this.initValue($this.value, $this.view);
      console.log(event, $this);
    }
  }
];
export const TemplateKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/template', data.id]
    }
  },
  {
    headerName: 'Model',
    field: 'modelClassName',
    type: 'select',
    options: [],
    editable: true,
    required: false,
    hide: true
  },
  {
    headerName: 'Nom du Template',
    field: 'templateName',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Corps du Template',
    field: 'templateBody',
    type: 'text',
    editable: true,
    required: true,
    longtext: true,
    cellEditor: 'agLargeTextCellEditor'
  },
];
export const ServiceItemKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/ussd-service-item', data.id]
    }
  },
  {
    headerName: 'Service',
    field: 'service',
    filterField: 'service.title',
    type: 'select',
    required: true,
    editable: false,
    options: [],
    cellRenderer: (item) => {
      return item.value ? item.value.title : item.value;
    },
    formatter: (item) => {
      return item.value ? item.value.id : item.value;
    }
  },
  {
    headerName: 'Titre',
    field: 'title',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Ordre',
    field: 'order',
    type: 'number',
    editable: true,
    required: true
  },
  {
    headerName: 'Colonne',
    field: 'targetField',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Tables cible',
    field: 'targetTables',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Params',
    field: 'params',
    editable: false,
    cellFormatter: (item) => {
      return mapToString(item.value)
    },
    formatter: (item) => {
      return mapToString(item.value)
    }
  },
  {
    headerName: 'Template',
    field: 'templateName',
    editable: true
  },
  {
    headerName: 'Fin',
    field: 'end',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is numeric',
    field: 'numeric',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Pattern',
    field: 'inputPattern',
    editable: true
  },
  {
    headerName: 'Is subscription',
    field: 'subscription',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Subscriber',
    field: 'subscriber',
    editable: true
  },
  {
    headerName: 'Is Payment needed',
    field: 'paymentNeeded',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Is External',
    field: 'external',
    type: 'checkbox',
    filter: 'booleanColumnFilter',
    editable: true,
    hideOnEdit: false,
    cellRendererFramework: CheckboxRenderer
  },
  {
    headerName: 'Service Type',
    field: 'serviceType',
    editable: true
  },
  {
    headerName: 'Data Items',
    field: 'items',
    hide: true,
    type: 'relatedList',
    dataSource: [],
    dataSourceSelected: [],
    keys: DataItemKeys,
    readonly: true,
    hideOnEdit: true,
    enableActions: true,
    enableAction: true,
    parentField: 'serviceItem',
    parentKey: 'serviceItem.id',
    baseUrl: '/ussd-data-item',
    onAdd: function(event, $this) {
      let items = $this.value[event.field].slice();
      items.push(event.item);
      $this.value[event.field] = items;
      $.ajax({
        url: `/api/ussd-data-item`,
        contentType: 'application/json',
        data: JSON.stringify({id: event.item.id, serviceItem: {id: $this.value.id}}),
        type: 'PUT'
      }).done(response => {
        console.log('Added', response);
        $this.postLoad();
        // $this.initValue($this.value, $this.view);
        console.log(event, $this);
      });
    },
    onRemove: function(event, $this) {
      $this.value[event.field] = $this.value[event.field].filter(e => e.id != event.item.id);
      $.ajax({
        url: `/api/ussd-data-item`,
        contentType: 'application/json',
        data: JSON.stringify({id: event.item.id, serviceItem: null}),
        type: 'PUT'
      }).done(response => {
        console.log('Removed', response);
        $this.postLoad();
        // $this.initValue($this.value, $this.view);
        console.log(event, $this);
      });
    }
  },
];
export const ServiceKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/ussd-service', data.id]
    }
  },
  {
    headerName: 'Titre',
    field: 'title',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Get Started words',
    field: 'started',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Table Cible',
    field: 'target',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Template',
    field: 'template',
    type: 'text',
    editable: true,
    required: false
  },
  {
    headerName: 'Description',
    field: 'description',
    type: 'textarea',
    editable: true,
    required: false
  },
  {
    headerName: 'Service Items',
    field: 'items',
    hide: true,
    type: 'relatedList',
    dataSource: [],
    dataSourceSelected: [],
    keys: ServiceItemKeys,
    readonly: true,
    hideOnEdit: true,
    enableActions: true,
    enableAction: true,
    parentField: 'service',
    parentKey: 'service.id',
    baseUrl: '/ussd-service-item',
    onAdd: function(event, $this) {
      /*let items = $this.value[event.field].slice();
      items.push(event.item);
      $this.value[event.field] = items;
      $.ajax({
        url: `/api/ussd-service-item`,
        contentType: 'application/json',
        data: JSON.stringify({id: event.item.id, service: {id: $this.value.id}}),
        type: 'PUT'
      }).done(response => {
        console.log('Added', response);
        $this.postLoad();
        console.log(event, $this);
      });*/
    },
    onRemove: function(event, $this) {
      /*$this.value[event.field] = $this.value[event.field].filter(e => e.id != event.item.id);
      $.ajax({
        url: `/api/ussd-service-item`,
        contentType: 'application/json',
        data: JSON.stringify({id: event.item.id, service: null}),
        type: 'PUT'
      }).done(response => {
        console.log('Removed', response);
        $this.postLoad();
        console.log(event, $this);
      });*/
    }
  },
  {
    headerName: 'Owner',
    field: 'ownerId',
    type: 'number',
    editable: true,
    required: false
  },
];

export const ProfileKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/profile', data.id]
    }
  },
  {
    headerName: 'Nom',
    field: 'name',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Description',
    field: 'summary',
    type: 'text',
    longtext: true,
    editable: true
  },
];

export const RoleKeys = [
  {
    headerName: 'Id',
    field: 'id',
    type: 'hidden',
    filter: 'agNumberColumnFilter',
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/role', data.id]
    }
  },
  {
    headerName: 'Nom',
    field: 'name',
    type: 'text',
    editable: true,
    required: true
  },
  {
    headerName: 'Description',
    field: 'summary',
    type: 'text',
    longtext: true,
    editable: true
  },
];

export const roleFormatter = (item) => {
  return (item.value || []).reduce((preview, current) => {
    return preview == '' ? current.name : `${preview};${current.name}`;
  }, '');
};

export const UserKeys = [
  {
    field: 'id',
    headerName: 'Id',
    type: 'hidden',
    required: false,
    cellRendererFramework: LinkCellComponent,
    link: (params) => {
      let data = params.data || {};
      if (!data.id) {
        return null;
      }
      return ['/users', data.id]
    }
  },
  {
    field: 'firstName',
    headerName: 'Prénom',
    type: 'text',
    required: true,
    editable: true
  },
  {
    field: 'lastName',
    headerName: 'Nom',
    type: 'text',
    required: true,
    editable: true
  },
  {
    field: 'profile',
    headerName: 'Profile',
    type: 'select',
    required: true,
    editable: false,
    readonly: function(user) {
      return user && !user.admin
    },
    options: [],
    cellRenderer: (item) => {
      return item.value ? item.value.name : item.value;
    },
    formatter: (item) => {
      return item.value ? item.value.id : item.value
    }
  },
  {
    field: 'username',
    headerName: 'Nom d\'utilisateur',
    type: 'email',
    required: true,
    editable: true
  },
  {
    field: 'roles',
    headerName: 'Roles',
    type: 'text',
    required: false,
    editable: false,
    readonly: function(user) {
      return user && !user.admin
    },
    cellFormatter: roleFormatter,
    formatter: roleFormatter
  },
  {
    field: 'password',
    headerName: 'Mot de passe',
    type: 'password',
    required: true,
    editable: false,
    cellRenderer: (item) => {
      return '******';
    }
  },
  {
    field: "confirmPassword",
    headerName: "Confirmation de mot de passe",
    type: "password",
    hide: true
  },
];

