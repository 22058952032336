import {Directive, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';

import $ from 'jquery';

@Directive({
  selector: '[date-picker]'
})

export class DatePickerDirective {
  @Input() value = '';
  @Input() field;
  @Input() minimumDate = false;
  @Input() dateFormat: string;
  @Input() timeFormat: string;
  @Input() datePickerOnly = false;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public el: ElementRef, public renderer: Renderer2) {
  }

  ngOnInit() {
    if (this.datePickerOnly === true) {
      $(this.el.nativeElement).datepicker({
        controlType: 'select'
        , oneLine: true,
        dateFormat: this.dateFormat || 'yy-mm-dd',
        minDate: this.minimumDate === false ? '' : new Date(),
        onSelect: (value) => {
          this.value = value;
          this.dateChange.next({event: this.el, control: {field: this.field, type: 'date'}, value: value});
        }
      })
        .datepicker('setDate', this.value);
    } else {
      $(this.el.nativeElement).datetimepicker({
        controlType: 'select',
        oneLine: true,
        dateFormat: this.dateFormat || 'yy-mm-dd',
        timeFormat: this.timeFormat || 'HH:mm:ss',
        minDate: this.minimumDate === false ? '' : new Date(),
        separator: ' ',
        onSelect: (value) => {
          this.value = value;
          this.dateChange.next({event: this.el, control: {field: this.field, type: 'date'}, value: value});
        }
      })
        .datepicker('setDate', this.value);
    }
  }
}
