import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Router} from '@angular/router';
import { BaseGridComponent } from '../../core/grid';
import { UsersService } from '../users.service';
import { UserKeys } from '../../app.interfaces';
import { CustomAuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends BaseGridComponent implements OnInit {

  constructor(private usersService: UsersService, router: Router, private authService: CustomAuthService) {
    super();
    this.service = usersService;
    this.router = router;
    this.baseUrl = '/users';
    this.actions = [
      {
        id: 'add',
        label: 'Add',
        icon: 'icon icon-plus',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: (event) => {
          this.router.navigate([this.baseUrl, 'new']);
        }
      },
      {
        id: 'export-excel',
        label: 'Export EXCEL',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        disabled: true,
        hide: true,
        onClick: this.onExportEXCEL.bind(this)
      },
      {
        id: 'export-csv',
        label: 'Exporter CSV',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: this.onExportCSV.bind(this)
      }
    ];
    this.authService.connectedProvider().subscribe(connected => {
      this.connected = connected;
    });
  }

  protected onCellEditingStopped(event) {
    let data = event.data;
    this.usersService.put(data)
      .toPromise()
      .then(response => {
        console.log('response', response);
      });
  }

  protected createColumnDefs() {
    return UserKeys;
  }

  protected loadData(pageNumber: number = -1, pageSize: number = -1): Observable<any> {
    return this.usersService.getPagination(pageNumber, pageSize);
  }

}
