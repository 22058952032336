import {Injectable} from '@angular/core';
import {BaseService, HttpService} from '../../shared/services';
import {ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
/**
 * Used in GenericComponent (Dynamic)
 */
export class GenericService extends BaseService {

  constructor(protected http: HttpService, private activatedRoute: ActivatedRoute) {
    super(http);
  }

  protected getBaseUrl(): string {
    let children = this.activatedRoute.snapshot.children;
    while(children[0].children && children[0].children.length > 0) {
      children = children[0].children;
    }
    let url = children[0].data ? children[0].data['service'] : '';
    console.log('url', url);
    return url;
  }

}
