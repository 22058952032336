import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {UserFormComponent} from "./user-form/user-form.component";
import {UserListComponent} from "./user-list/user-list.component";
import {UsersComponent} from "./users.component";
import {GrowlerModule} from "../core/growler/growler.module";
import {CoreModule} from "../core/core.module";
import {SharedModule} from "../shared/shared.module";
import {GridModule} from "../core/grid/grid.module";
import {ProfileComponent, ProfileFormComponent, ProfileListComponent} from './profile/profile.component';
import {RoleComponent, RoleFormComponent, RoleListComponent} from './role/role.component';
import {ProfileService} from "./profile/profile.service";
import {RoleService} from "./role/role.service";
import { AccessLevelComponent } from './profile/access-level/access-level.component';
import { UsersService } from './users.service';
import { FormModule } from '../core/form/form.module';
import { ActionsModule } from '../core/actions/actions.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    GrowlerModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    GridModule,
    FormModule,
    ActionsModule,
    FormsModule
  ],
  declarations: [
    UsersComponent,
    UserFormComponent,
    UserListComponent,
    ProfileComponent,
    ProfileListComponent,
    ProfileFormComponent,
    RoleComponent,
    RoleFormComponent,
    RoleListComponent,
    AccessLevelComponent
  ],
  providers: [ProfileService, RoleService, UsersService]
})
export class UsersModule {
}
