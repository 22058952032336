import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {GrowlerService} from "../../core/growler/growler.service";
import {Location} from "@angular/common";
import {ProfileService} from "./profile.service";
import {BaseGridComponent} from "../../core/grid/base-grid.component";
import {Observable} from "rxjs/Observable";
import {select} from "@angular-redux/store";
import { BaseFormImpl } from '../../core/form/base-form';
import { RoleKeys, IAppState, ProfileKeys } from '../../app.interfaces';
import { CustomAuthService } from '../../shared/services';

@Component({
  selector: 'app-profile',
  template: `
    <router-outlet></router-outlet>`
})
export class ProfileComponent {
}

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileFormComponent extends BaseFormImpl<any> implements OnInit {
  @Input()
  showDetails: boolean = true;
  itemKeys: any[] = RoleKeys;
  roles: any[] = [];
  excludedRoles: any[] = [];
  @select((state: IAppState) => state.userProfile.roles) $allRoles: Observable<any[]>;
  allRoles: any[] = [];
  accessLevels: any[] = [];

  constructor(protected fb: FormBuilder,
              protected service: ProfileService,
              protected location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              growler: GrowlerService) {
    super(fb, activatedRoute, service, location, router);
    this.growler = growler;
    this.baseUrl = '/profile';
    this.subscribe(this.$allRoles.subscribe(response => {
      this.allRoles = response || [];
    }));
  }

  ngOnInit() {
  }

  getKeys(): any[] {
    return ProfileKeys;
  }

  protected initValue(value?: any, view: boolean = false) {
    this.roles = (value.roles || []).map(item => {
      return item.role;
    });
    this.updateRelatedList();
    super.initValue(value, view);
    if (!this.value.accessLevels) {
      this.value.accessLevels = [];
    }
  }

  private updateRelatedList() {
    let rolesString = (this.roles || [])
      .map(role => role.name || role.role.name)
      .reduce((previousValue, currentValue) => {
        return previousValue === "" ? currentValue : `${previousValue},${currentValue}`;
      }, "");
    this.excludedRoles = (this.allRoles || []).filter(role => {
      return rolesString.indexOf(role.name) === -1;
    });
  }

  handleRemove(data: any) {
    console.log(data);
    this.service.removeRole(this.valueId, data.id)
      .toPromise()
      .then(response => {
        this.roles = this.roles.filter(role => (role.name || role.role.name) !== data.name);
        this.excludedRoles = [...this.excludedRoles, data];
      });
  }

  handleAdd(data: any) {
    this.service.addRole({
      profile: {id: this.valueId},
      role: {id: data.id}
    })
      .toPromise()
      .then((response: any) => {
        this.roles = [...this.roles, response.role];
        this.excludedRoles = this.excludedRoles.filter(role => role.name !== data.name)
      })
  }

  handleAccessLevelChanged(event) {
    console.log('profile:::event', event);
    let accesses = (this.value.accessLevels || []).filter(al => al.model !== event.model);
    accesses.push(event);
    this.value.accessLevels = accesses;
    console.log('accessLevels...', this.value);
  }

  submitForm($event, value) {
    value.accessLevels = this.value ? this.value.accessLevels : [];
    return super.submitForm($event, value);
  }
}


@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss']
})
export class ProfileListComponent extends BaseGridComponent {

  constructor(service: ProfileService, router: Router, private authService: CustomAuthService) {
    super();
    this.service = service;
    this.router = router;
    this.baseUrl = '/profile';
    this.actions = [
      {
        id: 'add',
        label: 'Add',
        icon: 'icon icon-plus',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: (event) => {
          this.router.navigate([this.baseUrl, 'new']);
        }
      },
      {
        id: 'export-excel',
        label: 'Export EXCEL',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        disabled: true,
        hide: true,
        onClick: this.onExportEXCEL.bind(this)
      },
      {
        id: 'export-csv',
        label: 'Exporter CSV',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: this.onExportCSV.bind(this)
      }
    ];
    this.authService.connectedProvider().subscribe(connected => {
      this.connected = connected;
    });
  }

  protected createColumnDefs() {
    return ProfileKeys;
  }

  protected loadData(pageNumber: number, pageSize: number): Observable<any> {
    return this.service.getPagination(pageNumber, pageSize);
  }

}
