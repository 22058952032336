import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {Router} from '@angular/router';
import {CustomAuthService, IUserLogin, SettingsService, ValidationService} from '../shared/services';
import {GrowlerMessageType, GrowlerService} from '../core/growler/growler.service';
import {RedirectService} from '../shared';

// import {NgRedux} from '@angular-redux/store';

@Component({
  selector: 'dd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  valForm: FormGroup;
  errorMessage: string;
  checkValue = '&#xf00c';

  constructor(public settings: SettingsService, public fb: FormBuilder,
              private authService: CustomAuthService,
              private growler: GrowlerService,
              private router: Router,
              private redirectService: RedirectService) {

    this.valForm = fb.group({
      'username': [null, Validators.compose([Validators.required, CustomValidators.username])],
      'password': [null, Validators.required]
    });
  }

  buildForm() {
    this.valForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, ValidationService.passwordValidator]]
    });
  }

  submit({value, valid}: { value: IUserLogin, valid: boolean }) {
    console.log('value', value);
    fetch(this.authService.authParams.loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(value)
    }).then(response => {
      let headers = response.headers;
      let token = headers.get("x-auth-token");
      console.log(token);
      localStorage.setItem("access_token", token);
      return response.json()
    }).then((connected: any) => {
        if (connected) {
          this.growler.growl('Logged in', GrowlerMessageType.Info);
          this.authService.handleConnected(connected);
          if (this.redirectService.redirectUrl) {
            let redirectUrl = this.redirectService.redirectUrl;
            if (redirectUrl === '/login') {
              redirectUrl = '/';
              this.redirectService.redirectUrl = redirectUrl;
            }
            this.router.navigate([redirectUrl]);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          const loginError = 'Unable to login';
          this.errorMessage = loginError;
          this.growler.growl(loginError, GrowlerMessageType.Danger);
        }
      },
      (err: any) => {
        console.log(err);
        const loginError = err.error.message || 'Unable to login';
        this.errorMessage = loginError;
        this.growler.growl(loginError, GrowlerMessageType.Danger);
      });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      if (this.valForm.controls.hasOwnProperty(c)) {
        this.valForm.controls[c].markAsTouched();
      }
    }
    if (this.valForm.valid) {
      console.log('Valid!');
      console.log(value);
    }
  }

  ngOnInit() {

  }

}
