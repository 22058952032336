import { Component, OnInit, HostBinding } from '@angular/core';
import { Subscribable, DjammaUser, SettingsService } from './shared/services';
import { Observable } from 'rxjs';
import { IAppState } from './app.interfaces';
import {select} from '@angular-redux/store';
import $ from 'jquery';

@Component({
  selector: 'app-layout',
  template: `
    <dd-layout [connected]="connected || {}" (onSearch)="handleSearch($event)">
      <router-outlet></router-outlet>
    </dd-layout>
  `
})
export class AppLayoutComponent extends Subscribable {
  @select((state: IAppState) => state.login.connected) $connected: Observable<DjammaUser>;
  connected: DjammaUser;

  constructor() {
    super();
    this.subscribe(this.$connected.subscribe(connected => {
      this.connected = connected;
    }));
  }

  handleSearch(term) {
    console.log('From app', term);
  }
}

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {

  constructor(public settings: SettingsService) {
  }

  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.layout.isFixed;
  }

  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.layout.isCollapsed;
  }

  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.layout.isBoxed;
  }

  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.layout.useFullLayout;
  }

  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.layout.hiddenFooter;
  }

  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.layout.horizontal;
  }

  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.layout.isFloat;
  }

  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen;
  }

  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.layout.asideToggled;
  }

  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  ngOnInit() {
    $(document).on('click', '[href="#"]', e => e.preventDefault());
  }
}
