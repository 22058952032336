import {Component} from "@angular/core";
import {Location} from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {GrowlerService} from "../../core/growler/growler.service";
import {Observable} from "rxjs/Observable";
import {select} from "@angular-redux/store";
import { BaseFormImpl } from '../../core/form/base-form';
import { ProfileKeys, IAppState, RoleKeys, UserKeys } from '../../app.interfaces';
import { DjammaUser } from '../../shared/services';
import { ValidationService } from '../../shared/services';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends BaseFormImpl<any> {

  showDetails: boolean = true;
  profileKeys: any[] = ProfileKeys;
  profiles: any[] = [];
  excludedProfiles: any[] = [];
  @select((state: IAppState) => state.userProfile.profiles) $allProfiles: Observable<any[]>;
  allProfiles: any[] = [];

  roleKeys: any[] = RoleKeys;
  roles: any[] = [];
  excludedRoles: any[] = [];
  @select((state: IAppState) => state.userProfile.roles) $allRoles: Observable<any[]>;
  allRoles: any[] = [];

  constructor(fb: FormBuilder, activatedRoute: ActivatedRoute,
              protected service: UsersService,
              protected growler: GrowlerService,
              protected location: Location) { // Model Driven validation
    super(fb, activatedRoute, service, location);
    this.growler = growler;
    this.baseUrl = '/users';

    this.subscribe(this.$allProfiles.subscribe(response => {
      this.allProfiles = response || [];
      this.postLoad();
    }));
    this.subscribe(this.$allRoles.subscribe(response => {
      this.allRoles = response || [];
    }));
  }

  protected initValue(value?: DjammaUser, view: boolean = false) {
    if (value && value.id) {
      this.roles = (value.roles || []);
      this.profiles = (value.profiles || []).map(item => {
        return item.profile;
      });
      this.updateRelatedList();
    }
    super.initValue(value, view);
  }

  private updateRelatedList() {
    let rolesString = (this.roles || [])
      .map(role => role.name || (role.role ? role.role.name : ''))
      .reduce((previousValue, currentValue) => {
        return `${previousValue}${currentValue};`;
      }, "");
    this.excludedRoles = (this.allRoles || []).filter(role => {
      return rolesString.indexOf(`${role.name};`) === -1;
    });
    let profilesString = (this.profiles || [])
      .map(profile => profile.name || profile.role.name)
      .reduce((previousValue, currentValue) => {
        return `${previousValue}${currentValue};`;
      }, "");
    this.excludedProfiles = (this.allProfiles || []).filter(profile => {
      return profilesString.indexOf(`${profile.name};`) === -1;
    });
  }

  handleRemoveRole(data: any) {
    this.service.removeRole(this.valueId, data.id)
      .toPromise()
      .then(response => {
        this.roles = this.roles.filter(role => (role.name || (role.role ? role.role.name : '')) !== data.name);
        this.excludedRoles = [...this.excludedRoles, data];
      });
  }

  handleAddRole(data: any) {
    this.service.addRole({
      userId: this.valueId,
      role: {id: data.id}
    })
      .toPromise()
      .then((response: any) => {
        this.roles = [...this.roles, response.role];
        this.excludedRoles = this.excludedRoles.filter(role => role.name !== data.name)
      })
  }

  handleRemoveProfile(data: any) {
    console.log(data);
    this.service.removeProfile(this.valueId, data.id)
      .toPromise()
      .then(response => {
        this.roles = this.roles.filter(role => (role.name || role.role.name) !== data.name);
        this.excludedRoles = [...this.excludedRoles, data];
      });
  }

  handleAddProfile(data: any) {
    this.service.addProfile({
      userId: this.valueId,
      profile: {id: data.id}
    })
      .toPromise()
      .then((response: any) => {
        this.profiles = [...this.profiles, response.profile];
        this.excludedProfiles = this.excludedProfiles.filter(profile => profile.name !== data.name)
      })
  }

  postLoad() {
    console.log('postLoad');
    this.dataFields = this.dataFields.map((item: any) => {
      if (item.field === 'profile') {
        item.options = (this.allProfiles || []).map(prof => {
          return {value: prof.id, label: prof.name};
        });
      }
      return item;
    });
  }

  getKeys(): any[] {
    return UserKeys;
  }

  createFormGroup() {
    let form = super.createFormGroup();
    form['confirmPassword'] = [this.value ? this.value.password : '', ValidationService.confirmPasswordValidator];
    return form;
  }

  submitForm($event, value) {
    value.roles = (value.roles || "").split(";").map(role => {
      return {
        name: role.trim(),
        userId: value.id
      }
    });
    value.profile = {
      id: value['profile']
    };
    console.log(value);
    return super.submitForm($event, value);
  }
}
