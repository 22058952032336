import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import {DataItemKeys, IAppState, ServiceItemKeys, ServiceKeys, TemplateKeys} from './app.interfaces';
import {Observable} from 'rxjs';
import {DjammaUser} from './shared';
import {AuthGuard, KeyStoreService, MenuService, RootService, TranslatorService} from './shared/services';
import {HomeComponent} from './home/home.component';
import {AppLayoutComponent} from './app.component';
import {GenericFormComponent, GenericListComponent} from './core/generic';
import {stringToListFormatter, stringToMap} from './shared/functions';
import {LoginComponent} from './login/login.component';
import {ADD_DATA_ITEM, ADD_SERVICE, ADD_SERVICE_ITEM} from './app.reducers';
import {UsersComponent} from './users/users.component';
import {UserListComponent} from './users/user-list/user-list.component';
import {UserFormComponent} from './users/user-form/user-form.component';
import {ProfileComponent, ProfileFormComponent, ProfileListComponent} from './users/profile/profile.component';
import {RoleComponent, RoleFormComponent, RoleListComponent} from './users/role/role.component';
import {environment} from '../environments/environment';

export const children: any = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {
    path: 'template',
    component: GenericListComponent,
    canActivate: [],
    data: {route: `/template`, service: `${environment.baseUrl}/template`}
  },
  {
    path: `template/new`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/template`, service: `${environment.baseUrl}/template`}
  },
  {
    path: `template/:id`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/template`, service: `${environment.baseUrl}/template`}
  },
  {
    path: 'ussd-service',
    component: GenericListComponent,
    canActivate: [],
    data: {route: `/ussd-service`, service: `${environment.baseUrl}/ussd-service`}
  },
  {
    path: `ussd-service/new`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-service`, service: `${environment.baseUrl}/ussd-service`}
  },
  {
    path: `ussd-service/:id`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-service`, service: `${environment.baseUrl}/ussd-service`}
  },
  {
    path: 'ussd-service-item',
    component: GenericListComponent,
    canActivate: [],
    data: {route: `/ussd-service-item`, service: `${environment.baseUrl}/ussd-service-item`}
  },
  {
    path: `ussd-service-item/new`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-service-item`, service: `${environment.baseUrl}/ussd-service-item`}
  },
  {
    path: `ussd-service-item/:id`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-service-item`, service: `${environment.baseUrl}/ussd-service-item`}
  },
  {
    path: 'ussd-data-item',
    component: GenericListComponent,
    canActivate: [],
    data: {route: `/ussd-data-item`, service: `${environment.baseUrl}/ussd-data-item`}
  },
  {
    path: `ussd-data-item/new`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-data-item`, service: `${environment.baseUrl}/ussd-data-item`}
  },
  {
    path: `ussd-data-item/:id`,
    component: GenericFormComponent,
    canActivate: [],
    data: {route: `/ussd-data-item`, service: `${environment.baseUrl}/ussd-data-item`}
  },
  {
    path: 'users',
    component: UsersComponent,
    children: [
      {path: '', redirectTo: 'list', canActivate: [AuthGuard], pathMatch: 'full'},
      {path: 'list', component: UserListComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: 'new', component: UserFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: ':id', component: UserFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: 'me/:id', component: UserFormComponent, canActivate: [AuthGuard], data: {roles: []}},
    ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {path: '', redirectTo: 'list', canActivate: [AuthGuard], pathMatch: 'full'},
      {path: 'list', component: ProfileListComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: 'new', component: ProfileFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: ':id', component: ProfileFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
    ]
  },
  {
    path: 'role',
    component: RoleComponent,
    children: [
      {path: '', redirectTo: 'list', canActivate: [AuthGuard], pathMatch: 'full'},
      {path: 'list', component: RoleListComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: 'new', component: RoleFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
      {path: ':id', component: RoleFormComponent, canActivate: [AuthGuard], data: {roles: ['ADMIN']}},
    ]
  }
]

// GenericModule.createRoutes('template', AuthGuard).forEach(route => children.push(route));
// GenericModule.createRoutes('ussd-service', AuthGuard).forEach(route => children.push(route));
// GenericModule.createRoutes('ussd-service-item', AuthGuard).forEach(route => children.push(route));
// GenericModule.createRoutes('ussd-data-item', AuthGuard).forEach(route => children.push(route));

console.log('children', children);

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: children
  },
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'home'}
];

const template = {
  text: 'Templates',
  link: '/template',
  icon: 'icon-settings',
  roles: []
};

const ussdService = {
  text: 'Services',
  link: '/ussd-service',
  icon: 'icon-settings',
  roles: []
};

const ussdServiceItem = {
  text: 'Service Items',
  link: '/ussd-service-item',
  icon: 'icon-settings',
  roles: []
};

const ussdDataItem = {
  text: 'Data Items',
  link: '/ussd-data-item',
  icon: 'icon-settings',
  roles: []
};

const Users = {
  text: 'Utilisateurs',
  link: '/users',
  icon: 'icon-user',
  submenu: [
    {
      text: 'List',
      link: '/users/list'
    },
    {
      text: 'Profiles',
      link: '/profile/list'
    },
    {
      text: 'Roles',
      link: '/role/list'
    }
  ],
  roles: ['ADMIN']
};

export const menu = [
  template,
  ussdService,
  ussdServiceItem,
  ussdDataItem,
  Users
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  @select((state: IAppState) => state.login.connected) $connected: Observable<DjammaUser>;
  @select((state: IAppState) => state.ussdStore.services) $services: Observable<any>;
  @select((state: IAppState) => state.ussdStore.serviceItems) $serviceItems: Observable<any>;
  @select((state: IAppState) => state.ussdStore.dataItems) $dataItems: Observable<any>;

  constructor(public menuService: MenuService
    , tr: TranslatorService
    , private ngRedux: NgRedux<IAppState>,
              public keyStoreService: KeyStoreService,
              private rootService: RootService) {
    menuService.addMenu(menu);
    this.$connected.subscribe(connected => {
      if (connected && connected.admin) {
        menuService.menuItems = [];
        menuService.addMenu(menu);
      }
    });
    this.keyStoreService.addKeys('/template', TemplateKeys);

    this.keyStoreService.addKeys('/ussd-service', ServiceKeys);
    this.keyStoreService.addData('/ussd-service', $this => {
      return this.$serviceItems.map(results => {
        return $this ? {
          dataFields: ($this.dataFields || $this).map(item => {
            if (item.field === 'items') {
              item.dataSource = results;
              item.dataSourceSelected = $this.value.items;
            }
            return item;
          })
        } : {};
      })
    });
    this.keyStoreService.addValueChanged('/ussd-service/beforeSubmit', $this => {
      const value = $this;
      if (value) {
        value.service = {id: value.service};
        value.params = stringToMap(value.params);
        value.items = (value.items || []).map(e => {
          return {id: e.id, service: {id: value.id}};
        });
        // this.rootService.putData('/ussd-service-item/list', value.items).subscribe(items => console.log('/ussd-service-item/list', items));
      }
    });
    this.keyStoreService.addValueChanged('/ussd-service/afterSubmit', value => {
      this.ngRedux.dispatch({
        type: ADD_SERVICE,
        payload: value
      });
    });

    this.keyStoreService.addKeys('/ussd-service-item', ServiceItemKeys);
    this.keyStoreService.addData('/ussd-service-item', $this => {
      return this.$services.map(results => {
        return $this ? {
          dataFields: ($this.dataFields || $this).map(item => {
            if (item.field === 'service') {
              item.options = (results || []).map(item => {
                return {value: item.id, label: item.title};
              });
            }
            return item;
          })
        } : {};
      })
    });
    this.keyStoreService.addData('/ussd-service-item', $this => {
      return this.$dataItems.map(results => {
        return $this ? {
          dataFields: ($this.dataFields || $this).map(item => {
            if (item.field === 'items') {
              item.dataSource = results;
              item.dataSourceSelected = $this.value.items;
            }
            return item;
          })
        } : {};
      })
    });
    this.keyStoreService.addValueChanged('/ussd-service-item/beforeSubmit', $this => {
      const value = $this;
      if (value) {
        value.service = {id: value.service};
        value.params = stringToMap(value.params);
        value.targetTables = stringToListFormatter(value.targetTables);
        value.items = (value.items || []).map(e => {
          return {id: e.id, serviceItem: {id: value.id}};
        });
        /*if(value.items.length > 0) {
          this.rootService.putData('/ussd-data-item/list', value.items).subscribe(items => console.log('/ussd-data-item/list', items));
        }*/
      }
    });
    this.keyStoreService.addValueChanged('/ussd-service-item/afterSubmit', value => {
      this.ngRedux.dispatch({
        type: ADD_SERVICE_ITEM,
        payload: value
      });
    });

    this.keyStoreService.addKeys('/ussd-data-item', DataItemKeys);
    this.keyStoreService.addData('/ussd-data-item', $this => {
      return this.$serviceItems.map(results => {
        return $this ? {
          dataFields: ($this.dataFields || $this).map(control => {
            if (control.field === 'serviceItem') {
              control.options = (results || []).map(it => {
                return {value: it.id, label: it.title};
              });
            }
            if (control.field === 'excludedServiceItem') {
              control.dataSource = results;
              let selected = (results || []).filter(v => {
                return $this.value.blackListServiceItems && $this.value.blackListServiceItems.find(e => e === v.id) != null;
              });
              console.log('selected', selected);
              control.dataSourceSelected = selected;
            }
            return control;
          })
        } : {};
      })
    });
    this.keyStoreService.addData('/ussd-data-item', $this => {
      return this.$dataItems.map((results: any) => {
        return $this ? {
          dataFields: ($this.dataFields || $this).map(control => {
            if (control.field === 'excludedDataItem') {
              control.dataSource = results;
              let selected = (results || []).filter(v => {
                return $this.value.blackListDataItems && $this.value.blackListDataItems.find(e => e === v.id) != null;
              });
              console.log('selected', selected);
              control.dataSourceSelected = selected;
            }
            return control;
          })
        } : {};
      })
    });
    this.keyStoreService.addValueChanged('/ussd-data-item/beforeSubmit', value => {
      console.log('value', value);
      if (value) {
        value.serviceItem = {id: value.serviceItem};
        value.params = stringToMap(value.params);
        value.blackListServiceItems = stringToListFormatter(value.blackListServiceItems);
        value.blackListDataItems = stringToListFormatter(value.blackListDataItems);
        console.log('value', value);
      }
    });
    this.keyStoreService.addValueChanged('/ussd-data-item/afterSubmit', value => {
      this.ngRedux.dispatch({
        type: ADD_DATA_ITEM,
        payload: value
      });
    });
  }
}
