import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserblockService} from '../sidebar/userblock/userblock.service';
import { MenuService, CustomAuthService, SettingsService } from 'src/app/shared/services';
import $ from 'jquery';

// import * as screenfull from 'screenfull';
// import * as browser from 'jquery.browser';

@Component({
  selector: 'dd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  @Output() onSearch = new EventEmitter<any>();

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', {static: false}) fsbutton;  // the fullscreen button

  @Input() connected: any = {};

  constructor(public menu: MenuService,
              public userblockService: UserblockService,
              public settings: SettingsService,
              public authService: CustomAuthService) {
    // super();
    // show only a few items on demo
    this.menuItems = menu.getMenu(); // for horizontal layout
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    /*if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }*/
    // FIXME
  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  toggleFullScreen(event) {
    /*if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('fa-expand').addClass('fa-compress');
    } else {
      el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }*/
  }

  onLogin() {
    console.log('logging in...');
    this.authService.fetchUser()
    .toPromise()
    .then(user => {
      this.authService.handleConnected(user);
    }).catch(error => {
      console.log('error', error);
      this.authService.handleLogout(false)
    });
  }

  onLogout() {
    console.log('logging out...');
    this.authService.logout().then(status => {
      this.authService.handleLogout(status);
    }).catch(res => {
      console.log(res);
      this.authService.handleLogout(false);
    });
  }

  handleSearch(term) {
    console.log('From header', term);
    this.onSearch.emit(term);
  }
}
