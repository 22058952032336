import {Component, Input} from '@angular/core';
import {BaseGridComponent} from '../../grid/base-grid.component';
import {Observable} from 'rxjs/Observable';
import {ActivatedRoute, Router} from '@angular/router';
import {GenericService} from '../generic.service';
import {KeyStoreService, DjammaUser, CustomAuthService} from '../../../shared/services';
import * as cells from '../../grid/cell/cell-renderer';
import {Subscription} from "rxjs/index";

@Component({
  selector: 'dd-generic-list',
  template: `
    <div class="container-fluid">
      <div dd-feat-height>
        <dd-grid [rowData]="rowData"
                 [columnDefs]="columnDefs"
                 [gridOptions]="gridOptions"
        ></dd-grid>
      </div>
      <div class="pull-right">
        <dd-actions classes="actions" [connected]="connected" [actions]="actions"></dd-actions>
      </div>
    </div>`
})
export class GenericListComponent extends BaseGridComponent {

  connected: DjammaUser;
  keySubscriptions: Subscription[] = [];
  @Input()
  actions: any[] = [
    {
      id: 'add',
      label: 'Add',
      icon: 'icon icon-plus',
      class: 'btn-primary',
      size: 2,
      onClick: (event) => {
        this.router.navigate([this.baseUrl, 'new']);
      }
    },
    {
      id: 'export-csv',
      label: 'Exporter CSV',
      class: 'btn-primary',
      size: 2,
      onClick: this.onExportCSV.bind(this)
    }
  ];
  dataFields: any[] = [];

  constructor(service: GenericService,
              router: Router,
              activatedRoute: ActivatedRoute,
              protected keyStoreService: KeyStoreService,
              private authService: CustomAuthService) {
    super();
    this.service = service;
    this.router = router;
    this.baseUrl = activatedRoute.snapshot.data['route'];
    this.authService.connectedProvider().subscribe(connected => {
      this.connected = connected;
    });
  }

  ngOnInit() {
    // Just for use
    console.log(cells.CELL);
    if(this.keyStoreService) {
      this.unsubscribe();
      (this.keyStoreService.getData(this.baseUrl) || []).forEach(observeFunc => {
        this.keySubscriptions.push(observeFunc(this.columnDefs).subscribe(data => {
          for (const key in data) {
            if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
              this[key] = data[key];
            }
          }
        }));
      });
    }
  }

  protected configureColumns() {
    if (this.keyStoreService) {
      const configure = super.configureColumns();
      this.dataFields = configure;
      return configure;
    }
    return null;
  }

  protected createColumnDefs() {
    return this.keyStoreService.getKeys(this.baseUrl);
  }

  protected loadData(pageNumber: number, pageSize: number): Observable<any> {
    return this.service.getPagination(pageNumber, pageSize);
  }

  unsubscribe() {
    this.keySubscriptions.forEach(sub => sub.unsubscribe());
    this.keySubscriptions = [];
  }
}
