import 'rxjs/add/observable/of';
import * as moment_ from 'moment';
import {Observable} from 'rxjs/Observable';
import {Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import { ValidationService } from './services';

const moment = moment_;

export function findItem(field: string, elements: any[]) {
  for (const item of elements) {
    if (item.group) {
      const result = findItem(field, item.elements);
      if (result !== null) {
        return result;
      }
    } else {
      if (item.field === field) {
        return item;
      }
    }
  }
  return null;
}

export function extractValue(value: any, field) {
  const fieldTree = field.split('.');
  let result = value;
  for (const childField of fieldTree) {
    if (!result) {
      return null;
    }
    result = result[childField];
  }
  return result;
}

export function constructValue(field, param) {
  const fieldTree = field.split('.');
  let value = {};
  let last = value, lastField;
  for (let i = 0; i < fieldTree.length-1; i++) {
    let childField = fieldTree[i];
    last[childField] = {};
    last = last[childField];
    lastField = childField;
  }
  last[fieldTree[fieldTree.length-1]] = param;
  return value;
}

export function datetimeFormatter(item) {
  const date = moment(item.value);
  return date.isValid() ? date.local().format('YYYY-MM-DD HH:mm:ss') : '';
}

export function dateFormatter(item) {
  const date = moment(item.value);
  return date.isValid() ? date.local().format('YYYY-MM-DD') : '';
}

export function pageFromList(data: any[], pageNumber: number = -1, pageSize: number = -1, totalCount?): Observable<any> {
  const totalEntityCount = totalCount || data.length;
  const allData = pageNumber <= 0 || pageSize <= 0;
  const pageCount = allData ? 1 : ((totalEntityCount / pageSize) + Math.ceil((totalEntityCount % pageSize) / pageSize));
  const start = (pageNumber - 1) * pageSize;
  const entities = totalCount ? data.slice() :
    data.slice(allData ? 0 : start, allData ? totalEntityCount :
      Math.min(pageNumber * pageSize, totalEntityCount));
  const pagination = {
    page: pageNumber,
    pageSize: pageSize,
    totalEntityCount: totalEntityCount,
    pageCount: pageCount,
    entities: entities
  };
  return Observable.of(pagination);
}

export function filterOnRoles(menus: any[], roles) {
  if (!menus || menus.length === 0) {
    return [];
  }
  const reduced = menus.filter(menu => {
    if (menu.hide === true) {
      return false;
    }
    if (menu.roles && menu.roles.length > 0) {
      for (const role of menu.roles) {
        if (roles.indexOf(`${role};`) >= 0) {
          return true;
        }
      }
      return false;
    }
    return true;
  });
  for (const item of reduced) {
    if (item.submenu) {
      item.submenu = filterOnRoles(item.submenu, roles);
    }
  }
  return reduced;
}

export function formatRoles(roles) {
  return (roles || []).map(role => role.name || (role.role ? role.role.name : ''))
    .reduce((previousValue, currentValue) => {
      return `${previousValue}${currentValue};`;
    }, '');
}

export const roleFormatter = (item) => {
  return (item.value || []).reduce((preview, current) => {
    return preview === '' ? current.name : `${preview};${current.name}`;
  }, '');
};

export function groupBy($this, key, projection?, toObject?) {
  return $this.reduce(function (rv, x) {
    let v;
    if (key instanceof Function) {
      v = key(x);
    } else {
      v = x[key];
    }
    const rvv = rv[v] || [];
    if (Array.isArray(rvv)) {
      rvv.push(projection instanceof Function ? projection(x) : x);
    }
    rv[v] = rvv;
    if (toObject === true && projection instanceof Function) {
      rv[v] = rv[v][0];
    }
    return rv;
  }, {});
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignored Error
  }
};

export function updateCheckFunc(item, value) {
  if (typeof item.required === 'function') {
    item.required = item.required(value);
    item._required = item.required;
  }
  if (typeof item.editable === 'function') {
    item.editable = item.editable(value);
    item._editable = item.editable;
  }
  if (typeof item.readonly === 'function') {
    item.readonly = item.readonly(value);
    item._readonly = item.readonly;
  }
}

export function createFormGroup(value, fields) {
  return fields.reduce((form, item) => {
    item = Object.assign({}, item);
    updateCheckFunc(item, value);
    const controlValue = [];
    const itemValue = (value !== undefined && value.id !== undefined) ? extractValue(value, item.field) : item.default;
    const formatter = item.formatter || item.cellFormatter;
    controlValue.push(itemValue !== undefined ? (formatter ? formatter({value: itemValue}) : itemValue) : '');
    switch (item.type) {
      case 'number':
        if (item.required) {
          controlValue.push(Validators.compose([Validators.required, CustomValidators.number]));
        } else {
          controlValue.push(CustomValidators.number);
        }
        break;
      case 'date':
        if (item.required) {
          controlValue.push(Validators.compose([Validators.required]));
        }
        break;
      case 'password':
        controlValue.push(Validators.compose([Validators.required, ValidationService.passwordValidator]));
        break;
      default:
        if (item.required) {
          controlValue.push(Validators.required);
        }
        break;
    }
    form[item.field] = controlValue;
    return form;
  }, {});
}

export function createFormControls(fields, thisValue) {
  return fields.map(value => {
    const item: any = Object.assign({}, value);
    const controlValue = [];
    updateCheckFunc(item, thisValue);
    switch (item.type) {
      case 'number':
        if (item.required) {
          controlValue.push(Validators.compose([Validators.required, CustomValidators.number]));
        } else {
          controlValue.push(CustomValidators.number);
        }
        break;
      case 'date':
        if (item.required) {
          controlValue.push(Validators.compose([Validators.required]));
        }
        break;
      case 'password':
        controlValue.push(ValidationService.passwordValidator);
        break;
      default:
        if (item.required) {
          controlValue.push(Validators.required);
        }
        break;
    }
    item.controlValue = controlValue;
    return item;
  });
}

export function listToStringFormatter(list: any[]) {
  return (list || []).reduce((preview, current) => {
    return preview == '' ? current : `${preview};${current}`;
  }, '');
}

export function stringToListFormatter(plainText: any = '') {
  if(plainText === null || plainText === undefined) {
    return null;
  }
  if(!isNaN(plainText)) {
    return [plainText];
  }
  if(typeof plainText !== 'string') {
    return plainText || [];
  }
  return (plainText || '').length > 0 ? plainText.split(';') : [];
}

export function stringToMap(plainText: string = '') {
  if(typeof plainText !== 'string') {
    return plainText || {};
  }
  return plainText.split(';')
  .map(text => text.trim().split('::', 2))
  .reduce((cumul, current) => {
    cumul[current[0]] = current[1];
    return cumul;
  }, {});
}

export function mapToString(params: any = {}) {
  let result = '';
  for(let key in params) {
    if(result.length === 0) {
      result = `${key}::${params[key]}`
    } else {
      result = `${result};${key}::${params[key]}`;
    }
  }
  return result;
}
