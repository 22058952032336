import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GrowlerComponent} from './growler.component';
import {GrowlerService, growlerService} from './growler.service';

@NgModule({
  imports: [CommonModule],
  exports: [GrowlerComponent],
  providers: [
    {
      provide: GrowlerService,
      useFactory: growlerService,
      deps: []
    }
  ],
  declarations: [GrowlerComponent]
})
export class GrowlerModule {
}
