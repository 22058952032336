import {Component, Input} from '@angular/core';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'dd-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent {

  @Input() gridOptions: GridOptions;
  @Input() columnDefs: any[];
  @Input() rowData: any[];
  @Input() enableActions: boolean;
  @Input() actions: any[];

  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }
}

