import { Injectable } from '@angular/core';
import { BaseService, HttpService } from '../../shared/services';

@Injectable()
export class ProfileService extends BaseService {

  constructor(protected http: HttpService) {
    super(http);
  }

  protected getBaseUrl(): string {
    return "/api/profile";
  }

  public addRole(item: any) {
    return this.http.post('/api/profilerole', item)
  }

  public removeRole(profileId: number, roleId: number) {
    return this.http.delete(`/api/profilerole/${profileId}/${roleId}`);
  }

}

