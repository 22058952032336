import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'dd-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  view: boolean;
  @Input()
  readonly: boolean;
  @Input()
  dataForm: FormGroup;
  @Input()
  dataFields: any = [];
  @Input()
  relatedColumns: any[] = [];
  @Input()
  value: any;
  @Input()
  baseUrl: string;
  @Input()
  enableAction = true;
  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSaveAndNew: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  submitForm($event, value) {
    this.onSubmit.emit({event: $event, value: value});
  }

  saveAndNew($ev, value) {
    this.onSaveAndNew.emit({event: $ev, value: value});
  }

  edit($event) {
    this.onEdit.emit({event: $event});
  }

  cancel($event) {
    this.onCancel.emit({event: $event});
  }

  valueChanged($event, control, value) {
    console.log('valueChanged...', $event);
    this.onValueChanged.emit({event: $event, control: control, value: value});
  }

  onDateChanged($event) {
    console.log('onDateChanged...', $event);
    this.onValueChanged.emit({event: $event.event, control: $event.control, value: $event.value});
  }
}
