import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlerService} from '../../growler/growler.service';
import {Location} from '@angular/common';
import {BaseFormImpl} from '../../form/base-form';
import {GenericService} from '../generic.service';
import {KeyStoreService} from '../../../shared/services';
import * as cells from '../../grid/cell/cell-renderer';
import {Subscription} from 'rxjs/index';
import { constructValue } from 'src/app/shared/functions';

@Component({
  selector: 'dd-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.css']
})
export class GenericFormComponent extends BaseFormImpl<any> implements OnInit {
  relatedColumns: any[];
  keySubscriptions: Subscription[] = [];
  constructor(protected fb: FormBuilder,
              protected service: GenericService,
              protected location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              growler: GrowlerService,
              protected keyStoreService: KeyStoreService) {
    super(fb, activatedRoute, service, location, router);
    this.growler = growler;
    this.baseUrl = this.activatedRoute.snapshot.data['route'];
    this.initValue(this.value, this.view);
    this.postLoad();
    this.activatedRoute.queryParams.subscribe(params => {
      let fields = {};
      for(let p in params) {
        if(p != 'view') {
          fields = Object.assign(fields, constructValue(p, params[p]));
        }
      }
      this.dataForm.patchValue(fields);
    })
  }

  ngOnInit() {
    // Just for use
    console.log(cells.CELL);
  }

  getKeys(): any[] {
    return this.keyStoreService ? this.keyStoreService.getKeys(this.baseUrl) : [];
  }

  postLoad(): void {
    if (this.keyStoreService) {
      this.unsubscribe();
      (this.keyStoreService.getData(this.baseUrl) || []).forEach(observeFunc => {
        this.keySubscriptions.push(observeFunc(this).subscribe(data => {
          for (const key in data) {
            if (data.hasOwnProperty(key) && this.hasOwnProperty(key)) {
              this[key] = data[key];
            }
          }
          let keys = this.keyStoreService ? this.keyStoreService.getKeys(this.baseUrl) : []; 
          if(this.dataFields) {
            keys = this.dataFields;
          } 
          this.relatedColumns = keys.filter(v => v.type === 'relatedList');
        }));
      });
    }
  }

  unsubscribe() {
    this.keySubscriptions.forEach(sub => sub.unsubscribe());
    this.keySubscriptions = [];
  }

  valueChanged($event, control, newValue) {
    super.valueChanged($event, control, newValue);
    if (this.keyStoreService) {
      (this.keyStoreService.getValueChanged(this.baseUrl) || []).forEach(observeFunc => {
        const result = observeFunc(this, $event, control, newValue) || '';
        if (result.indexOf('init;') >= 0) {
          this.initValue(this.value, this.view);
        }
        if (result.indexOf('load;') >= 0) {
          this.postLoad();
        }
      });
    }
  }

  protected beforeSubmit(value) {
    if (this.keyStoreService) {
      (this.keyStoreService.getValueChanged(`${this.baseUrl}/beforeSubmit`) || []).forEach(observeFunc => {
        observeFunc(value);
      });
    }
  }

  protected afterSubmit(value) {
    if (this.keyStoreService) {
      (this.keyStoreService.getValueChanged(`${this.baseUrl}/afterSubmit`) || []).forEach(observeFunc => {
        observeFunc(value);
      });
    }
  }

  handleAdd($event: any, $this) {
    console.log('this', this, $event, $this);
    const data = $event.item || $event;
    const formValue = Object.assign({}, (this || $this).dataForm.value);
    let relatedValues = (formValue[$event.field] || []).slice();
    relatedValues = [...relatedValues, data];
    formValue[$event.field] = relatedValues;
    (this || $this).dataForm.patchValue(formValue);
    console.log('add:data', (this || $this).value, data, relatedValues);
    (this || $this).postLoad();
  }

  handleRemove($event: any, $this) {
    console.log('this', this, $event, $this);
    const data = $event.item || $event;
    const formValue = Object.assign({}, (this || $this).dataForm.value);
    let relatedValues = formValue[$event.field] || [];
    relatedValues = relatedValues.filter(e => e.id !== data.id);
    formValue[$event.field] = relatedValues;
    (this || $this).dataForm.patchValue(formValue);
    console.log('remove:data', (this || $this).value, data, relatedValues);
    (this || $this).postLoad();
  }
}
