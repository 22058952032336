import { Injectable } from '@angular/core';
import { select } from '@angular-redux/store';
import { IAppState } from './app.interfaces';
import { Observable } from 'rxjs';
import { DjammaUser } from './shared';
import { SettingsService } from './shared/services';

@Injectable()
export class USSDSettingsService extends SettingsService {
  @select((state: IAppState) => state.login.connected) $connected: Observable<DjammaUser>;

  constructor() {
    super();
    this.app = {
      name: 'USSD',
      logo: './assets/img/dd-logo.png',
      description: 'Djamma Dev USSD',
      year: ((new Date()).getFullYear()),
      userRoute: '/users/me'
    };
    this.$connected.subscribe(user => {
      this.user = user || {};
      this.user.name = `${this.user.firstName} ${this.user.lastName}`;
      this.user.picture = './assets/img/user.png';
    });
    console.log(this.app);
    console.log(this.user);
  }
}