import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {IFilterAngularComp} from 'ag-grid-angular';
import { IFilterParams, RowNode, IDoesFilterPassParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'boolean-filter-cell',
  template: `
    <div class="ag-filter">
      <div>
        <div class="ag-filter-body">
          <div>
            Check/Uncheck
            <input type="checkbox" #input (ngModelChange)="onChange($event)" [(ngModel)]="value"
                                       class="ag-filter-filter"
                                       id="filterText">
          </div>
        </div>
        <div class="ag-filter-apply-panel" id="applyPanel">
          <button type="button" id="clearButton" (click)="onChange('')">Clear Filter</button>
        </div>
      </div>
    </div>
  `
})
export class BooleanColumnFilter implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public value = '';

  @ViewChild('input', {read: ViewContainerRef, static: true}) public input;

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.value !== null && this.value !== undefined && this.value !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.value.toLowerCase()
      .split(' ')
      .every((filterWord) => {
        return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
      });
  }

  getModel(): any {
    return this.value === '' ? null : {
      filter: this.value,
      filterType: 'checkbox',
      type: 'equals'
    };
  }

  setModel(model: any): void {
    this.value = model ? (model.value === true ? 'true' : 'false') : '';
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  onChange(newValue): void {
    if (this.value !== newValue) {
      this.value = newValue;
      this.params.filterChangedCallback();
    }
  }
}
