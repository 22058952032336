import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { Subscribable } from '../../../shared/services';


const READ = 0b0001;
const CREATE = 0b0010;
const EDIT = 0b0100;
const DELETE = 0b1000;

@Component({
  selector: 'app-access-level',
  templateUrl: './access-level.component.html',
  styleUrls: ['./access-level.component.scss']
})
export class AccessLevelComponent extends Subscribable implements OnChanges {
  //@select((state: IAppState) => state.classes.models) $models: Observable<any[]>;
  models: any = [
      {name: 'USSDService', label: 'USSD Service', value: 'USSDService'},
      {name: 'USSDServiceItem', label: 'USSD ServiceItem', value: 'USSDServiceItem'},
      {name: 'DataItem', label: 'Data Item', value: 'DataItem'},
      {name: 'Template', label: 'Template', value: 'Template'},
      {name: 'User', label: 'User', value: 'User'}
    ];
  @Input()
  profile: any;
  accessLevels: any;
  @Output()
  onAccessLevelChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  view: boolean;
  ready = READ;
  edit = EDIT;
  create = CREATE;
  delete = DELETE;

  constructor() {
    super();
    this.accessLevels = this.models.map(model => {
      let al: any = Object.assign({}, model);
      al.level = 0;
      al.ready = false;
      al.create = false;
      al.edit = false;
      al.delete = false;
      al.model = al.name;
      return al;
    }).groupBy('model', it => it, true);
    this.view = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile) {
      if (this.profile) {
        let accessLevels = (this.profile.accessLevels || []).map(access => {
          let al = Object.assign({}, access);
          al.ready = (access.level & READ) > 0;
          al.create = (access.level & CREATE) > 0;
          al.edit = (access.level & EDIT) > 0;
          al.delete = (access.level & DELETE) > 0;
          return al;
        }).groupBy('model', it => it, true);
        this.accessLevels = Object.assign({}, this.accessLevels, accessLevels);
      }
    }
  }

  handleAccessLevelChanged(event) {
    console.log(event);
    let al = Object.assign({}, event);
    al.ready = event.ready === true ? READ : 0;
    al.edit = event.edit === true ? EDIT : 0;
    al.create = event.create === true ? CREATE : 0;
    al.delete = event.delete === true ? DELETE : 0;
    console.log('al', al);
    let access = {
      id: event.id,
      profile: {id: this.profile.id},
      model: al.model,
      level: (al.ready | al.edit | al.create | al.delete)
    };
    console.log('access', access);
    this.onAccessLevelChanged.emit(access);
  }
}
