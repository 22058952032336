import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {GrowlerService} from "../../core/growler/growler.service";
import {Location} from "@angular/common";
import {Observable} from "rxjs/Observable";
import {BaseGridComponent} from "../../core/grid/base-grid.component";
import {RoleService} from "./role.service";
import { BaseFormImpl } from '../../core/form/base-form';
import { RoleKeys } from '../../app.interfaces';
import { CustomAuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-role',
  template: '<router-outlet></router-outlet>'
})
export class RoleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

@Component({
  selector: 'app-role-form',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleFormComponent extends BaseFormImpl<any> {
  constructor(protected fb: FormBuilder,
              protected service: RoleService,
              protected location: Location,
              router: Router,
              activatedRoute: ActivatedRoute,
              growler: GrowlerService) {
    super(fb, activatedRoute, service, location, router);
    this.growler = growler;
    this.baseUrl = '/role';
  }

  getKeys(): any[] {
    return RoleKeys;
  }
}

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent extends BaseGridComponent {

  constructor(service: RoleService, router: Router, private authService: CustomAuthService) {
    super();
    this.service = service;
    this.router = router;
    this.baseUrl = '/role';
    this.actions = [
      {
        id: 'add',
        label: 'Add',
        icon: 'icon icon-plus',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: (event) => {
          this.router.navigate([this.baseUrl, 'new']);
        }
      },
      {
        id: 'export-excel',
        label: 'Export EXCEL',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        disabled: true,
        hide: true,
        onClick: this.onExportEXCEL.bind(this)
      },
      {
        id: 'export-csv',
        label: 'Exporter CSV',
        roles: ['ADMIN'],
        class: 'btn-primary',
        size: 2,
        onClick: this.onExportCSV.bind(this)
      }
    ];
    this.authService.connectedProvider().subscribe(connected => {
      this.connected = connected;
    });
  }
  protected createColumnDefs() {
    return RoleKeys;
  }

  protected loadData(pageNumber: number, pageSize: number): Observable<any> {
    return this.service.getPagination(pageNumber, pageSize);
  }

}
