import {Component, Input, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import { SettingsService } from 'src/app/shared/services';

@Component({
  selector: 'dd-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {

  @Input()
  connected: any = {};

  constructor(public userblockService: UserblockService, public settings: SettingsService) {
  }

  ngOnInit() {
  }

  userBlockIsVisible() {
    return this.connected.id && this.userblockService.getVisibility();
  }

}
