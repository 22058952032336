import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ItemAddRemoveComponent} from '../cell/cell-renderer';
import {Router} from '@angular/router';
import {BaseGridComponent} from '../base-grid.component';
import {Observable} from 'rxjs/Rx';
import {RootService, DjammaUser, CustomAuthService} from '../../../shared/services';
import {pageFromList} from '../../../shared/functions';

@Component({
  selector: 'dd-related-list',
  templateUrl: './related-list.component.html',
  styleUrls: ['./related-list.component.scss']
})
export class RelatedListComponent extends BaseGridComponent implements OnChanges {
  @Input()
  enableAction = true;
  @Input()
  navigable = true;
  @Input()
  itemId: number;
  @Input()
  selectedItems: any[] = [];
  @Input()
  field: string;
  @Input()
  dataPath: string;
  @Input()
  parentKey: string;
  @Input()
  itemKeys: any[] = [];
  @Input()
  items: any[] = [];
  @Input()
  isOn: boolean;
  @Input()
  baseUrl = '/';
  @Input()
  enableActions: boolean = true;
  @Input()
  parentField: string;
  @Input()
  connected: DjammaUser;
  @Input()
  actions: any[] = [
    {
      id: 'add',
      label: 'Add',
      icon: 'icon icon-plus',
      class: 'btn-primary',
      size: 2,
      onClick: (event) => {
        this.router.navigate([this.baseUrl, 'new'], {queryParams: {[this.parentField]: this.itemId}});
      }
    }
  ];
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

  constructor(router: Router, service: RootService,
    private authService: CustomAuthService) {
    super();
    this.router = router;
    this.service = service;
    // this.enableActions = false;
    this.authService.connectedProvider().subscribe(connected => {
      this.connected = connected;
    });
  }

  onCellDoubleClicked(event) {
    if (event.colDef.field !== this.field && this.navigable) {
      this.router.navigate([this.baseUrl, event.data.id]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.selectedItems) {
      if (this.gridOptions.api) {
        this.rowData = this.items;
        const datasource = this.getDatasource();
        if (datasource != null) {
          this.gridOptions.api.setDatasource(datasource);
        } else {
          this.gridOptions.api.setRowData(this.rowData);
        }
        this.gridOptions.api.hideOverlay();
      }
    }
  }

  protected createColumnDefs() {
    const columns: any[] = [];
    if (this.enableAction) {
      columns.push({
        field: this.field,
        width: 40,
        headerName: '',
        filter: false,
        sortable: false,
        dataItemId: this.itemId,
        cellRendererFramework: ItemAddRemoveComponent,
        suppressFilter: true,
        onRemove: this.handleRemove.bind(this),
        onAdd: this.handleAdd.bind(this),
        isOn: this.isOn,
        getStatus: this.getStatus.bind(this)
      });
    }
    this.itemKeys.forEach(item => {
      columns.push(Object.assign({}, item, {editable: false}));
    });
    return columns;
  }

  handleRemove(data: any) {
    this.onRemove.emit({item: data, field: this.field});
  }

  handleAdd(data: any) {
    this.onAdd.emit({item: data, field: this.field});
  }

  getStatus(params) {
    if (params) {
      const data = params.data;
      const values = this.selectedItems || [];
      const find = values.find(e => data && data.id === e.id);
      return find && find !== undefined && find !== null;
    }
    return false;
  }

  protected loadData(pageNumber: number, pageSize: number): Observable<any> {
    return this.dataPath ? this.service.getDataByParams(this.dataPath, {
      page: `${pageNumber}`,
      perPage: `${pageSize}`,
      [this.parentKey]: `${this.itemId}`
    }) : pageFromList(this.items || [], pageNumber, pageSize);
  }

  protected searchData(filter: any, pageNumber: number, pageSize: number, path: string = ''): Observable<any> {
    if (!this.params) {
      this.params = {};
    }
    if(this.parentKey) {
      this.params[this.parentKey] = `${this.itemId}`;
    }
    return super.searchData(filter, pageNumber, pageSize, this.baseUrl);
  }
}
