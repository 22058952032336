import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {filterOnRoles, formatRoles, DjammaUser} from '../../shared';

@Component({
  selector: 'dd-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnChanges {
  @Input()
  classes: string;
  @Input()
  actions: any[];
  displayedActions: any[];
  @Input()
  connected: DjammaUser;
  @Input()
  enableActions: boolean = true;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions || changes.connected) {
      this.updateActions();
    }
  }

  protected updateActions() {
    if (this.connected) {
      const roles = formatRoles(this.connected.roles);
      this.displayedActions = filterOnRoles(this.actions, roles);
    }
  }
}
