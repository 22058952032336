import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'dd-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  @Input() showHeader = true;
  @Input() showSidebar = true;
  @Input() showOffSidebar = false;
  @Input() showFooter = true;
  @Input() connected: any = {};
  @Output() onSearch = new EventEmitter<any>();

  constructor() {
  }

  handleSearch(term) {
    console.log('From layout', term);
    this.onSearch.emit(term);
  }
}
