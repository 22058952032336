import {Injectable} from '@angular/core';
import { BaseService, HttpService } from '../shared/services';

@Injectable()
export class UsersService extends BaseService {

  protected getBaseUrl(): string {
    return '/api/user';
  }

  constructor(protected http: HttpService) {
    super(http);
  }

  public addRole(item: any) {
    return this.http.post('/api/userrole', item)
  }

  public removeRole(userId: number, roleId: number) {
    return this.http.delete(`/api/userrole/${userId}/${roleId}`);
  }

  public addProfile(item: any) {
    return this.http.post('/api/userprofile', item)
  }

  public removeProfile(userId: number, profileId: number) {
    return this.http.delete(`/api/userprofile/${userId}/${profileId}`);
  }

}
