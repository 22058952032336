import { DjammaUser } from './services';

export class LoginState {
  auth?: boolean;
  connected?: DjammaUser;
}


export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_AUTH = 'SET_AUTH';
export const LOGOUT = 'LOGOUT';

export function loginReducer(state: LoginState = <LoginState>{connected: {}, auth: false}, action: any) {
  switch (action.type) {
    case SET_CONNECTED:
      return Object.assign({}, state, action.payload);
    case SET_AUTH:
      return Object.assign({}, state, {auth: action.payload});
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
