import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GenericListComponent} from './list/generic-list.component';
import {GenericFormComponent} from './form/generic-form.component';
import {GenericService} from './generic.service';
import {SharedModule} from '../../shared/shared.module';
import {ActionsModule} from '../actions/actions.module';
import {GridModule} from '../grid/grid.module';
import {GrowlerModule} from '../growler/growler.module';
import {FormModule} from '../form/form.module';
import { Routes } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ActionsModule,
    GridModule,
    GrowlerModule,
    FormModule
  ],
  declarations: [
    GenericListComponent,
    GenericFormComponent
  ],
  exports: [
    GenericListComponent,
    GenericFormComponent
  ],
  providers: [GenericService],
  entryComponents: [
    GenericListComponent,
    GenericFormComponent
  ]
})
export class GenericModule {
  static createRoutes(base: string, canActivate) {
    let routes : Routes = [
      {
        path: base,
        component: GenericListComponent,
        canActivate: [],
        data: {route: `/${base}`, service: `/api/${base}`}
      },
      {
        path: `${base}/new`,
        component: GenericFormComponent,
        canActivate: [],
        data: {route: `/${base}`, service: `/api/${base}`}
      },
      {
        path: `${base}/:id`,
        component: GenericFormComponent,
        canActivate: [],
        data: {route: `/${base}`, service: `/api/${base}`}
      }
    ];
    return routes.map(route => {
      if(canActivate) {
        route.canActivate.push(canActivate);
      }
      return route;
    });
  }
}
