import {GridOptions, IDatasource} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CheckboxRenderer, ItemRemoveComponent} from './cell/cell-renderer';
import {saveAs} from 'file-saver';
import {BooleanColumnFilter} from './filter/column-filter';
import {
  extractValue,
  Subscribable,
  BaseService
} from '../../shared';
import {PicklistEditor} from "./cell/cell-editor";

/**
 * Created by sissoko on 30/03/2018.
 */

export const DEFAULT_PAGE_SIZE = 100;

export abstract class BaseGridComponent extends Subscribable implements OnInit {

  gridOptions: GridOptions;
  rowData: any[] = [];
  eItems: any[];
  eColumns: any[];
  columnDefs: any[];
  service: BaseService;
  filterModel: any;
  router: Router;
  baseUrl: string;
  overlayLoadingTemplate = '<div class="card-body loader-demo d-flex align-items-center justify-content-center">' +
    '                        <div class="ball-clip-rotate-multiple">' +
    '                           <div></div>' +
    '                           <div></div>' +
    '                        </div>' +
    '                     </div>';
  enableActions = true;
  params: any;
  actions: any[];
  connected: any;

  constructor() {
    super();
    this.gridOptions = {
      defaultColDef: {
        // allow every column to be aggregated
        enableValue: true,
        filter: true
        // headerCheckboxSelection: this.isFirstColumn.bind(this),
        // checkboxSelection: this.isFirstColumn.bind(this)
      },
      overlayLoadingTemplate: this.overlayLoadingTemplate,
      enableSorting: true,
      // enableFilter: true,
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      enableGroupEdit: true,
      showToolPanel: true,
      rowModelType: 'infinite',
      onCellDoubleClicked: this.onCellDoubleClicked.bind(this),
      onGridReady: this.onGridReady.bind(this),
      onCellEditingStarted: this.onCellEditingStarted.bind(this),
      onCellEditingStopped: this.onCellEditingStopped.bind(this),
      singleClickEdit: true,
      paginationAutoPageSize: true,
      rowSelection: 'multiple',
      enableColResize: true,
      suppressRowClickSelection: true,
      frameworkComponents: {booleanColumnFilter: BooleanColumnFilter, picklistEditor: PicklistEditor}
    };
    this.actions = [];
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  ngOnInit(): void {
  }

  protected abstract loadData(pageNumber: number, pageSize: number): Observable<any>;

  protected abstract createColumnDefs();

  protected onExportEXCEL($event) {
  }

  protected onExportCSV($event) {
    const params = this.getAllParams();
    params['perPage'] = -1;
    params['page'] = -1;
    this.service.getDataByParams('/list', params)
      .toPromise()
      .then((data: any[]) => {
        this.exportResultCSV(data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  protected getAllParams() {
    return Object.assign({}, this.params);
  }

  protected exportResultCSV(data: any[]) {
    const header = this.createColumnDefs();
    const dataArray = [];
    dataArray.push(header.reduce((previousValue, current) => {
      return previousValue === '' ? `"${current.headerName}"` : `${previousValue};"${current.headerName}"`;
    }, ''));
    data.map(item => {
      dataArray.push(header.reduce((previousValue, current) => {
        const value = extractValue(item, current.field) || '';
        return previousValue === '' ? `"${value}"` : `${previousValue};"${value}"`;
      }, ''));
    });
    const body = dataArray.reduce((previousValue, current) => {
      return previousValue === '' ? `${current}` : `${previousValue}\n${current}`;
    }, '');
    const filename = this.getExportFilename();
    const blob = new Blob([body], {type: 'text/plain;charset=UTF-8'});
    saveAs(blob, filename);
  }

  protected searchData(filter: any, pageNumber: number, pageSize: number, path: string = ''): Observable<any> {
    console.log(filter);
    if (this.service) {
      if (!this.params) {
        this.params = {};
      }
      this.params['page'] = `${pageNumber}`;
      this.params['perPage'] = `${pageSize}`;
      return this.service.search(filter, this.params, path);
    }
    return this.loadData(pageNumber, pageSize);
  }

  protected configureColumns() {
    const columns = this.createColumnDefs().slice();
    if (this.enableActions) {
      columns.push(
        {
          headerName: 'Active',
          width: 100,
          field: 'active',
          type: 'checkbox',
          filter: 'booleanColumnFilter',
          editable: true,
          default: true,
          cellRendererFramework: CheckboxRenderer
        });
      columns.push({
        field: 'actions',
        headerName: '',
        width: 40,
        filter: false,
        sortable: false,
        cellRendererFramework: ItemRemoveComponent,
        onClick: (data) => {
          if (this.service) {
            this.service.remove(data.id)
              .toPromise()
              .then(response => {
                const datasource = this.getDatasource();
                if (datasource != null) {
                  this.gridOptions.api.setDatasource(datasource);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
      });
    }
    return columns;
  }

  protected onCellDoubleClicked(params) {
    if (this.router && this.baseUrl) {
      this.router.navigate([this.baseUrl, params.data.id]);
    }
  }

  protected onCellEditingStarted(event) {
    console.log('onCellEditingStarted', event);
  }

  protected onCellEditingStopped(event) {
    console.log('onCellEditingStopped', event);
    if (this.service) {
      this.service.put(event.data)
        .toPromise()
        .then(response => {
          console.log(response);
        })
        .catch(response => {
          console.error(response);
        });
    }
  }

  protected onGridReady(event) {
    event.api.setColumnDefs(this.configureColumns());
    this.gridOptions.api = event.api;
    const datasource = this.getDatasource();
    if (datasource != null) {
      event.api.setDatasource(datasource);
    } else {
      event.api.setRowData(this.rowData);
    }
    event.api.hideOverlay();
  }

  protected getDatasource(): IDatasource {
    return {
      getRows: (params) => {
        const pageNumber = params.endRow / DEFAULT_PAGE_SIZE;
        let request;
        const fields = [];
        let filterModel = params.filterModel;
        if (this.filterModel) {
          filterModel = Object.assign({}, filterModel, this.filterModel);
        }
        for (const field in filterModel) {
          let column = this.gridOptions.columnApi.getColumn(field);
          if (filterModel.hasOwnProperty(field)) {
            let colDef: any = column.getColDef();
            if(colDef.filterField) {
              filterModel[colDef.filterField] = filterModel[field];
              delete filterModel[field];
            }
            fields.push(field);
          }
        }
        let sortModel = params.sortModel.map(col => {
          let column = this.gridOptions.columnApi.getColumn(col.colId);
          let colDef: any = column.getColDef();
          if(colDef.filterField) {
            col.colId = colDef.filterField;
          }
          return col;
        });
        const filter = {
          filterModel: filterModel,
          sortModel: sortModel
        };
        if (fields.length || sortModel.length) {
          request = this.searchData(filter, pageNumber, DEFAULT_PAGE_SIZE);
        } else {
          request = this.loadData(pageNumber, DEFAULT_PAGE_SIZE);
        }
        request
          .toPromise()
          .then((page: any) => {
            const totalCount = (page.page - 1) * page.pageSize + (page.entities || []).length;
            if (this.gridOptions.api) {
              this.gridOptions.api.setInfiniteRowCount(Math.max(totalCount, page.totalEntityCount));
            }
            params.successCallback(page.entities || [], Math.max(totalCount, page.totalEntityCount));
          })
          .catch(error => {
            console.log(error);
            params.failCallback();
          });
      }
    };
  }

  protected reloadData() {
    if (this.gridOptions.api) {
      this.gridOptions.api.showLoadingOverlay();
      this.gridOptions.api.setDatasource(this.getDatasource());
      this.gridOptions.api.hideOverlay();
    }
  }

  protected getExportFilename() {
    return 'export.csv';
  }
}
