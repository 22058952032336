import {Directive, ElementRef, Input} from '@angular/core';

import $ from 'jquery';

@Directive({
  selector: '[dd-feat-height]'
})
export class FeatHeightDirective {
  @Input()
  targetElementId = '#footer';

  constructor(public el: ElementRef) {
  }

  ngOnInit() {
    console.log('this.targetElementId', this.targetElementId);
    const $element = $(this.el.nativeElement);
    const eoffset = $element.offset();
    const elementTop = eoffset.top;
    console.log('elementTop: ', elementTop);
    const $target = $(this.targetElementId);
    console.log($target);
    const offset = $target.offset();
    if (offset) {
      const targetTop = offset.top;
      console.log('targetTop: ', targetTop);
      const height = Math.max(targetTop - elementTop - 15, 200);
      $element.height(height);
      console.log('height: ', height);
    } else {
      $element.height(500);
    }
  }
}
