import {FormBuilder, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {EventEmitter} from '@angular/core';
import {BaseService, extractValue, Subscribable} from '../../shared';
import {GrowlerMessageType, GrowlerService} from '../growler/growler.service';
import {CheckboxRenderer} from '../grid/cell/cell-renderer';
import {createFormControls, createFormGroup} from "../../shared/functions";

export abstract class BaseForm<T> extends Subscribable {
  dataForm: FormGroup;
  dataFields: any = [];
  value: T;
  valueId: any;
  activatedRoute: ActivatedRoute;
  view = false;
  baseUrl: string;
  router: Router;
  readonly = false;
  activeButton = true;

  valueUpdate: EventEmitter<T> = new EventEmitter<T>();

  protected growler: GrowlerService;

  constructor(protected fb: FormBuilder, activatedRoute: ActivatedRoute,
              protected service: BaseService,
              protected location: Location, router?: Router) {
    super();
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.dataForm = fb.group(this.createFormGroup());
    this.dataFields = this.createFormFields();
    this.subscribe(activatedRoute.params.subscribe((params: Params) => {
      this.valueId = params['id'];
      this.reloadValue();
    }));
    this.subscribe(activatedRoute.queryParams.subscribe(params => {
      this.view = params['view'] && params['view'] === 'true';
    }));
  }

  reloadValue() {
    if (this.valueId) {
      this.service.getById(this.valueId)
        .toPromise()
        .then(response => {
          this.initValue(<T> response, this.view);
          this.postLoad();
        })
        .catch((error: HttpErrorResponse) => {
          console.log(error);
        });
    }
  }

  abstract getKeys(): any[];

  setValue(value: T) {
    console.log('value', value);
    this.value = value;
    this.valueUpdate.emit(value);
  }

  createFormGroup() {
    const value: any = this.value;
    const fields = this.getKeys().slice();
    if (this.activeButton) {
      fields.push({
        headerName: 'Active',
        field: 'active',
        type: 'checkbox',
        filter: 'booleanColumnFilter',
        editable: true,
        default: true,
        cellRendererFramework: CheckboxRenderer
      });
    }
    return createFormGroup(value, fields);
  }

  createFormFields() {
    const fields = this.getKeys().slice();
    if (this.activeButton) {
      fields.push({
        headerName: 'Active',
        field: 'active',
        type: 'checkbox',
        filter: 'booleanColumnFilter',
        editable: true,
        default: true,
        cellRendererFramework: CheckboxRenderer
      });
    }
    return createFormControls(fields, this.value);
  }

  submitForm($ev, value): Promise<boolean> {
    $ev.preventDefault();
    this.beforeSubmit(value);
    for (const c in this.dataForm.controls) {
      if (this.dataForm.controls.hasOwnProperty(c)) {
        this.dataForm.controls[c].markAsTouched();
      }
    }
    if (this.dataForm.valid) {
      return this.service.upsert(value)
        .toPromise()
        .then((response: any) => {
          if (this.growler) {
            this.growler.growl('Traitement réussi.', GrowlerMessageType.Success);
          }
          this.initValue(response, true);
          this.postLoad();
          this.afterSubmit(response);
          return true;
        })
        .catch(response => {
          if (this.growler) {
            this.growler.growl(response.error.message, GrowlerMessageType.Danger);
          }
          this.view = false;
          return false;
        });
    }
    return Promise.reject(false);
  }

  saveAndNew($ev, value) {
    this.submitForm($ev, value).then(response => {
      if (response) {
        this.initValue();
        this.postLoad();
      }
    });
  }

  protected initValue(value: T = <T> {}, view: boolean = false) {
    this.setValue(value || <T> {});
    const form = this.createFormGroup();
    this.dataForm = this.fb.group(form);
    this.dataFields = this.createFormFields();
    this.view = view;
  }

  protected postLoad() {
  }

  protected beforeSubmit(value) {
  }

  protected afterSubmit(value) {
  }

  cancel($event) {
    $event.preventDefault();
    if (!this.baseUrl || !this.router) {
      this.location.back();
    } else {
      this.router.navigate([this.baseUrl]);
    }
  }

  edit($event) {
    $event.preventDefault();
    this.view = false;
  }

  private getValue(value: T, field) {
    return extractValue(value, field);
  }

  onChange(field: string, event) {
  }

  valueChanged($event: any, control: any, newValue?) {
    if (control.type === 'checkbox') {
      this.value[control.field] = !this.value[control.field];
      this.dataForm.patchValue({[control.field]: this.value[control.field]});
    } else if (newValue) {
      if (!this.value) {
        this.value = <T>{};
      }
      this.value[control.field] = newValue;
      // this.dataForm.patchValue({[control.field]: newValue});
    }
  }
}

export abstract class BaseFormImpl<T> extends BaseForm<T> {

  handleCancel($event) {
    this.cancel($event.event);
  }

  handleEdit($event) {
    this.edit($event.event);
  }

  handleSaveAndNew($event) {
    this.saveAndNew($event.event, $event.value);
  }

  handleSubmit($event) {
    this.submitForm($event.event, $event.value);
  }

  handleValueChanged($event) {
    this.valueChanged($event.event, $event.control, $event.value);
  }
}
