/**
 * Created by sissoko on 21/09/2019.
 */
import {Component, Input} from "@angular/core";
import {ICellEditorAngularComp} from "ag-grid-angular/src/interfaces";
import {ICellEditorParams} from "ag-grid-community";

@Component({
  selector: 'picklist',
  template: `<select class="form-control"
                     [attr.disabled]="(control.readonly) ? true : null"
                     title="{{control.headerName}}"
                     [(ngModel)]="value"
                     (change)="onChange(control.field)">
    <option value="">{{control.headerName}}</option>
    <option *ngFor="let opt of control.options"
            value="{{opt.value}}">
      {{opt.label || opt.value}}
    </option>
  </select>`
})
export class PicklistEditor implements ICellEditorAngularComp {

  @Input()
  value: any;
  control: any;

  agInit(params: ICellEditorParams): void {
    this.control = params.column.getColDef();
    let value = params.value || params.node.data[this.control.field];
    if (typeof value === 'object') {
      this.value = value.id || value.value;
    }
  }

  getValue(): any {
    return this.value;
  }

  onChange(field) {
    if (typeof this.control.onChange === 'function') {
      this.control.onChange(field, this.value);
    }
  }
}
