export interface USSDStore {
  services?: any[];
  serviceItems?: any[];
  dataItems?: any[];
}

export interface UserProfileStore {
  profiles?: any[];
  roles?: any[];
}

export const ADD_DATA_ITEM = 'ADD_DATA_ITEM';
export const ADD_SERVICE_ITEM = 'ADD_SERVICE_ITEM';
export const ADD_SERVICE = 'ADD_SERVICE';

export const REMOVE_DATA_ITEM = 'REMOVE_DATA_ITEM';
export const REMOVE_SERVICE_ITEM = 'REMOVE_SERVICE_ITEM';
export const REMOVE_SERVICE = 'REMOVE_SERVICE';

export const SET_DATA_ITEMS = 'SET_DATA_ITEMS';
export const SET_SERVICE_ITEMS = 'SET_SERVICE_ITEMS';
export const SET_SERVICES = 'SET_SERVICES';

export const SET_ROLES = 'SET_ROLES';
export const SET_PROFILES = 'SET_PROFILES';

export const LOGOUT = 'LOGOUT';

export function ussdReducer(state: USSDStore = <USSDStore>{
  services: [],
  serviceItems: [],
  dataItems: []
}, action: any) {
  switch (action.type) {
    case SET_SERVICES:
      return Object.assign({}, state, {services: action.payload});
    case SET_SERVICE_ITEMS:
      return Object.assign({}, state, {serviceItems: action.payload});
    case SET_DATA_ITEMS:
      return Object.assign({}, state, {dataItems: action.payload});

    case ADD_DATA_ITEM:
      let dataItems = state.dataItems.slice().filter(e => e.id != action.payload.id);
      dataItems.push(action.payload);
      return Object.assign({}, state, {dataItems: dataItems});
    case ADD_SERVICE_ITEM:
      let serviceItems = state.serviceItems.slice().filter(e => e.id != action.payload.id);
      serviceItems.push(action.payload);
      return Object.assign({}, state, {serviceItems: serviceItems});
    case ADD_SERVICE:
      let services = state.services.slice().filter(e => e.id != action.payload.id);
      services.push(action.payload);
      return Object.assign({}, state, {services: services});

    case REMOVE_DATA_ITEM:
      return Object.assign({}, state, {dataItems: state.dataItems.filter(e => e.id != action.payload.id)});
    case REMOVE_SERVICE_ITEM:
      return Object.assign({}, state, {serviceItems: state.serviceItems.filter(e => e.id != action.payload.id)});
    case REMOVE_SERVICE:
      return Object.assign({}, state, {services: state.services.filter(e => e.id != action.payload.id)});
    case LOGOUT:
      return {};
    default:
      return state;
  }
}

export function userProfileReducer(state: UserProfileStore = <UserProfileStore>{roles: [], profiles: []}, action: any) {
  switch (action.type) {
    case SET_ROLES:
      return Object.assign({}, state, {roles: action.payload});
    case SET_PROFILES:
      return Object.assign({}, state, {profiles: action.payload});
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
