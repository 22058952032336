import {Injectable} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';

const PASSWORD_REGEX = /^(?=.*[a-zA-Z!@#$%^&*])(?!.*\s).{6,100}$/;

@Injectable()
export class ValidationService {

  static getValidatorErrorMessage(code: string) {
    const config = {
      'required': 'Required',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'invalidConfirmPassword': 'Confirm Password does not match password',
      'invalidDate': 'Date is not correct'
    };
    return config[code];
  }

  static passwordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (control.value && control.value.match(PASSWORD_REGEX)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  static emailValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (!Validators.email(control)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  static confirmPasswordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (!control.parent) {
      return null;
    }
    const controls: any = control.parent.controls;
    if (controls === null || controls.password === null) {
      return null;
    }
    if (control.value && control.value.match(PASSWORD_REGEX)) {
      if (control.value === controls.password.value) {
        return null;
      }
    }
    return {'invalidConfirmPassword': true};
  }
}
