import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import {throwError, Subscription} from 'rxjs';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface Model {
  id: number;
  createDate?: Date;
  modifyDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
  active: boolean;
}

export interface DjammaUser extends Model {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  roles: any[];
  userRoles: any[];
  profiles: any[];
  admin: boolean;
  logs: any[];
  profile: any;
}

@Injectable()
export class MenuService {

  menuItems: Array<any>;

  constructor() {
    this.menuItems = [];
  }

  addMenu(items: Array<{
    text: string,
    order?: number,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>) {
    items.forEach((item) => {
      this.menuItems.push(item);
    });
  }

  getMenu() {
    return this.menuItems;
  }

}

@Injectable()
export class RedirectService {
  public redirect500: string = '';
  public redirect404: string = '';
  public redirect401: string = '';
  public redirectUrl: string;
  public reason: any;
}

@Injectable()
export class HttpService {

  constructor(private http: HttpClient,
    private router: Router,
    private redirectService: RedirectService) {
  }

  getAccessToken() {
    return localStorage.getItem('access_token')
  }

  getOptions(options) {
    let finalOptions = options || {}
    let finalHeaders = finalOptions.headers || {}
    let accessToken = this.getAccessToken();
    if (!accessToken || finalHeaders['Authorization']) return options
    finalHeaders = Object.assign({}, finalHeaders, {"Authorization" : `Bearer ${accessToken}`})
    finalOptions.headers = finalHeaders;
    console.log(finalOptions);
    return finalOptions;
  }

  /**
   * Construct a GET request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    return this.http.get(url, this.getOptions(options))
      .catch(this.catchAuthError.bind(this))
      ;
  }

  /**
   * Construct a POST request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    return this.http.post(url, body, this.getOptions(options))
      .catch(this.catchAuthError.bind(this))
      ;
  }

  /**
   * Construct a PUT request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    return this.http.put(url, body, this.getOptions(options))
      .catch(this.catchAuthError.bind(this))
      ;
  }

  /**
   * Construct a DELETE request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    return this.http.delete(url, this.getOptions(options))
      .catch(this.catchAuthError.bind(this))
      ;
  }

  /**
   * Construct a REMOVE request which interprets the body as JSON and returns it.
   *
   * @return an `Observable` of the body as an `Object`.
   */
  remove(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    // $.ajax({url: url, method: 'REMOVE'}).done(r => console.log('done', r));
    return this.http.request('REMOVE', url, this.getOptions(options))
        .catch(this.catchAuthError.bind(this));
  }

  catchAuthError(res: HttpErrorResponse) {
    // we have to pass HttpService's own instance here as `self`return (res: Response) => {
    console.log(res);
    this.redirectService.reason = res.error;
    if (res.status === 401) {
      // if not authenticated
      this.redirectService.redirectUrl = this.router.url;
      localStorage.removeItem('state');
      this.router.navigate([this.redirectService.redirect401]);
    } else if (res.status === 404) {
      this.router.navigate([this.redirectService.redirect404]);
    } else if (res.status === 500) {
      this.router.navigate([this.redirectService.redirect500]);
    }
    return throwError(res);
  }
}

export abstract class BaseService {

  constructor(protected http: HttpService) {
  }

  protected abstract getBaseUrl(): string;

  public getDataByParams(path: string = '', params?: HttpParams | { [param: string]: string | string[]; }) {
    return this.http.get(`${this.getBaseUrl()}${path.indexOf('/') === 0 || path.length === 0 ? path : ('/' + path)}`, {params: params});
  }

  public getDataByUrlParams(path: string = '', params?: HttpParams | { [param: string]: string | string[]; }) {
    let morePath = '';
    for (const index in params) {
      if (params.hasOwnProperty(index)) {
        morePath = morePath + '"' + index + '" : "' + params[index] + '"';
      }
    }
    return this.http.get(`${this.getBaseUrl()}${path.indexOf('/') === 0 || path.length === 0 ? path : ('/' + path)}` + morePath);
  }

  public getPagination(page: number = -1, perPage: number = -1) {
    return this.getDataByParams('', {page: `${page}`, perPage: `${perPage}`});
  }

  public getById(id: number) {
    return this.http.get(`${this.getBaseUrl()}/${id}`);
  }

  public post(item: any) {
    return this.http.post(this.getBaseUrl(), item);
  }

  public postData(path: string = '', data: any) {
    return this.http.post(`${this.getBaseUrl()}${path.indexOf('/') === 0 || path.length === 0 ? path : ('/' + path)}`, data);
  }

  public putData(path: string = '', data: any) {
    return this.http.put(`${this.getBaseUrl()}${path.indexOf('/') === 0 || path.length === 0 ? path : ('/' + path)}`, data);
  }

  public put(item: any) {
    if (!item || !item.id) {
      console.warn('Put method needs an id into the item', item);
    }
    return this.http.put(this.getBaseUrl(), item);
  }

  public upsert(item: any) {
    if (item.id) {
      return this.put(item);
    }
    return this.post(item);
  }

  public delete(id: number) {
    return this.http.delete(`${this.getBaseUrl()}/${id}`);
  }

  public remove(id: number) {
    return this.http.remove(`${this.getBaseUrl()}/${id}`);
  }

  public search(body: any | null, params?: { [param: string]: string; }, path: string = '') {
    let url = `${this.getBaseUrl()}${ path === '/' ? '' : path}/search`;
    if (params) {
      let query = '';
      for (const param in params) {
        if (query === '') {
          query = `${param}=${params[param]}`;
        } else {
          query = query + `&${param}=${params[param]}`;
        }
      }
      if (query !== '') {
        url = `${url}?${query}`;
      }
    }
    return this.http.post(url, body);
  }

  public searchWithParams(body: any | null, pageNumber: number, pageSize: number) {
    return this.search(body, {page: `${pageNumber}`, perPage: `${pageSize}`});
  }
}

@Injectable()
export class RootService extends BaseService {
  constructor(protected http: HttpService) {
    super(http);
  }

  protected getBaseUrl(): string {
    return '/api';
  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  fetchUser: () => Observable<DjammaUser>;
  handleConnectedUser: (connectedUser: DjammaUser) => void;
  accessChecker: (user: DjammaUser, requiredRoles) => boolean;

  constructor(protected router: Router, protected redirectService: RedirectService) {
    this.fetchUser = (): Observable<DjammaUser> => {
      return throwError('Need authentication');
    };
    this.handleConnectedUser = (connectedUser: DjammaUser) => {
      console.log('connectedUser', connectedUser);
    };
    this.accessChecker = (user: DjammaUser, requiredRoles): boolean => {
      return true;
    };
    console.log('AuthGuard');
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.fetchUser()
      .toPromise()
      .then(user => {
        this.handleConnectedUser(user);
        const requiredRoles = next.data['roles'] || [];
        const accessGranted = this.accessChecker(user, requiredRoles);
        /*if (!accessGranted) {
          this.router.navigate(['/403']);
        }*/
        return accessGranted;
      })
      .catch(this.handleAuthError.bind(this));
  }

  handleAuthError(response) {
    if (response.error) {
      this.handleConnectedUser(null);
      const code = Math.floor(response.error.code / 100);
      if (response.error.code === 401) {
        console.log('this.router.url', this.router.url);
        if (this.router.url && this.router.url !== '/500' && this.router.url !== '/404') {
          this.redirectService.redirectUrl = this.router.url;
        }
        this.router.navigate(['/login']);
      } else if (code === 4) {
        this.router.navigate(['/404']);
      } else {
        this.router.navigate(['/500']);
      }
      return false;
    } else {
      console.log(response);
    }
  }
}

@Injectable()
export class UpdateUserGuard extends AuthGuard {

  handleAuthError(response) {
    console.log(response);
    if (response.error) {
      this.handleConnectedUser(null);
      return true;
    }
  }
}

@Injectable()
export class AdminService extends BaseService {

  constructor(protected http: HttpService) {
    super(http);
  }

  getModelClasses() {
    return this.getDataByParams('/models');
  }

  getJobClasses() {
    return this.getDataByParams('/jobs');
  }

  getProviderClasses() {
    return this.getDataByParams('/providers');
  }

  clearCache() {
    return this.http.delete(`${this.getBaseUrl()}/memcache`);
  }

  protected getBaseUrl(): string {
    return '/api/admin';
  }

}

@Injectable()
export class KeyStoreService {
  keyStore: {
    [path: string]: any[]
  };
  dataStore: {
    [path: string]: ((keys) => Observable<any>)[];
  };
  valueChangedStore: {
    [path: string]: ((value, $event?, control?, newValue?) => any)[];
  };

  constructor() {
    this.keyStore = {};
    this.dataStore = {};
    this.valueChangedStore = {};
  }

  getKeyStore() {
    return this.keyStore;
  }

  getKeys(path: string) {
    return this.keyStore[path] || [];
  }

  addKeys(path: string, keys: any[]) {
    this.keyStore[path] = keys || [];
  }

  getDataStore() {
    return this.dataStore;
  }

  getData(path: string) {
    return this.dataStore[path];
  }

  addData(path: string, apply: (args) => Observable<any>) {
    if (!this.dataStore[path]) {
      this.dataStore[path] = []
    }
    this.dataStore[path].push(apply);
  }

  // Value Changed
  getValueChangedStore() {
    return this.valueChangedStore;
  }

  getValueChanged(path: string) {
    return this.valueChangedStore[path];
  }

  addValueChanged(path: string, valueChanged: (value, $event, control, newValue) => any) {
    if (!this.valueChangedStore[path]) {
      this.valueChangedStore[path] = []
    }
    this.valueChangedStore[path].push(valueChanged);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public user: any;
  public app: any;
  public layout: any;
  constructor() {
    // User Settings
    // -----------------------------------
    this.user = {
      name: 'John',
      job: 'ng-developer',
      picture: './assets/img/user.png'
    };
    // App Settings
    // -----------------------------------
    this.app = {
      name: 'Djamma Dev',
      year: 2018,
      logo: './assets/img/dd-logo.png',
      userRoute: 'users'
    };
    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: 'ng-fadeInUp'
    };
  }
  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }
  setAppSetting(name, value) {
    return this.app[name] = value;
  }
  setUserSetting(name, value) {
    return this.user[name] = value;
  }
  setLayoutSetting(name, value) {
    return this.layout[name] = value;
  }
  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }
}

export abstract class Subscribable implements OnDestroy {

  subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(subscription: Subscription) {
    this.subscriptions.push(subscription);
    return subscription;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}

const PASSWORD_REGEX = /^(?=.*[a-zA-Z!@#$%^&*])(?!.*\s).{6,100}$/;
@Injectable()
export class ValidationService {

  static getValidatorErrorMessage(code: string) {
    const config = {
      'required': 'Required',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'invalidConfirmPassword': 'Confirm Password does not match password',
      'invalidDate': 'Date is not correct'
    };
    return config[code];
  }

  static passwordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (control.value && control.value.match(PASSWORD_REGEX)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  static emailValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (!Validators.email(control)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  static confirmPasswordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?!.*\s)          - Spaces are not allowed
    if (!control.parent) {
      return null;
    }
    const controls: any = control.parent.controls;
    if (controls === null || controls.password === null) {
      return null;
    }
    if (control.value && control.value.match(PASSWORD_REGEX)) {
      if (control.value === controls.password.value) {
        return null;
      }
    }
    return {'invalidConfirmPassword': true};
  }
}

export interface IUserLogin {
  username: string;
  password: string;
}

export interface AuthParams {
  loginUrl: string;
  fetchUserUrl: string;
  registerUrl: string;
  logoutUrl: string;
}

export abstract class AuthService {
  authParams: AuthParams;
  handleConnected: (connected: DjammaUser) => void;
  handleLogout: (status: any) => void;
  connectedProvider: () => Observable<DjammaUser>;

  abstract login(userLogin: IUserLogin): Observable<DjammaUser>;

  abstract fetchUser(): Observable<DjammaUser>;

  abstract register(user: DjammaUser): Observable<DjammaUser>;

  abstract logout();

}

@Injectable()
export class CustomAuthService implements AuthService {
  authParams: AuthParams;
  handleConnected: (connected: DjammaUser) => void;
  handleLogout: (status: any) => void;
  connectedProvider: () => Observable<DjammaUser>;

  constructor(protected http: HttpClient) {
    this.authParams = <AuthParams>{
      loginUrl: '/api/auth',
      fetchUserUrl: '/api/auth',
      registerUrl: '/api/auth/register',
      logoutUrl: '/api/auth',
    };
    this.handleConnected = connected => {
      console.log(connected);
    };
    this.handleLogout = (status) => {
      console.log('status', status);
    };
    this.connectedProvider = () => {
      return Observable.of(<DjammaUser>{});
    };
  }

  login(userLogin: IUserLogin): Observable<DjammaUser> {
    return this.http.post<DjammaUser>(this.authParams.loginUrl, userLogin);
  }

  fetchUser(): Observable<DjammaUser> {
    return this.http.get<DjammaUser>(this.authParams.fetchUserUrl);
  }

  register(user: DjammaUser): Observable<DjammaUser> {
    return this.http.post<DjammaUser>(this.authParams.registerUrl, user);
  }

  logout() {
    return this.http.delete(this.authParams.logoutUrl, {})
      .toPromise()
      .catch(res => {
        return Promise.resolve(res.status === 401);
      });
  }
}

@Injectable()
export class BasicAuthService extends CustomAuthService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  login(userLogin: IUserLogin): Observable<DjammaUser> {
    const token = btoa(`${userLogin.username}:${userLogin.password}`);
    return this.http.get<DjammaUser>(this.authParams.loginUrl, {headers: {'Authorization': `Basic ${token}`}});
  }
}

export function customAuthServiceFactory(httpClient: HttpClient, params: AuthParams) {
  const authService = new CustomAuthService(httpClient);
  if (params) {
    authService.authParams = params;
  }
  return authService;
}

export function basicAuthServiceFactory(httpClient: HttpClient, params: AuthParams) {
  const authService = new BasicAuthService(httpClient);
  if (params) {
    authService.authParams = params;
  }
  return authService;
}

@Injectable()
export class TranslatorService {

    private defaultLanguage: string = 'en';

    private availablelangs = [
        { code: 'en', text: 'English' },
        { code: 'es_AR', text: 'Spanish' }
    ];

    constructor(public translate: TranslateService) {

        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        this.useLanguage();

    }

    useLanguage(lang: string = null) {
        this.translate.use(lang || this.translate.getDefaultLang());
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

}

