import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UpdateUserGuard, MenuService, AdminService, AuthGuard, HttpService, RedirectService, KeyStoreService, RootService, TranslatorService, SettingsService } from './services';
import { RouterModule } from '@angular/router';
import { FeatHeightDirective } from './directives/feat-height/feat-height.directive';
import { DatePickerDirective } from './directives/datepicker/datepicker.directive';
import { CheckallDirective } from './directives/checkall/checkall.directive';

@NgModule({
  declarations: [
    FeatHeightDirective,
    DatePickerDirective,
    CheckallDirective
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    FeatHeightDirective,
    DatePickerDirective,
    CheckallDirective
  ],
  providers: [
    SettingsService,
    MenuService,
    AdminService,
    AuthGuard,
    UpdateUserGuard,
    HttpService,
    RedirectService,
    KeyStoreService,
    RootService,
    TranslatorService
  ]
})
export class SharedModule { }
