import {Component, OnInit} from '@angular/core';
import { SettingsService } from '../../shared/services';

@Component({
  selector: 'dd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public settings: SettingsService) {
  }

  ngOnInit() {
  }

}
