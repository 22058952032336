import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, AppLayoutComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { loadState, loginReducer, saveState, SET_CONNECTED, LOGOUT, formatRoles } from './shared';
import { Store, createStore, combineReducers, applyMiddleware } from 'redux';
import { IAppState } from './app.interfaces';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomAuthService, customAuthServiceFactory, AuthGuard, UpdateUserGuard, DjammaUser, RedirectService, SettingsService, MenuService, RootService } from './shared/services';
import { USSDSettingsService } from "./app.services";
import { NgReduxModule, NgRedux, select } from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HomeComponent } from './home/home.component';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { ussdReducer, SET_SERVICES, SET_SERVICE_ITEMS, SET_DATA_ITEMS, userProfileReducer, SET_PROFILES, SET_ROLES } from './app.reducers';
import { environment } from 'src/environments/environment';
import { GenericFormComponent, GenericListComponent } from './core/generic';
import { UsersModule } from './users/users.module';

// the second parameter 'fr' is optional
registerLocaleData(localeFr);

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const persistedState = loadState();

// Construct more reducers there.
export const store: Store<IAppState> = createStore(
  combineReducers<IAppState>({
    login: loginReducer,
    ussdStore: ussdReducer,
    userProfile: userProfileReducer
  }),
  persistedState,
  applyMiddleware()
);

store.subscribe(() => {
  saveState(store.getState());
});

let prototype: any = Array.prototype;
prototype.groupBy = function (key, projection?, toObject?) {
  return this.reduce(function (rv, x) {
    let v;
    if(key instanceof Function) {
      v = key(x);
    } else {
      v = x[key]
    }
    let rvv = rv[v] || [];
    if (Array.isArray(rvv))
      rvv.push(projection instanceof Function ? projection(x) : x);
    rv[v] = rvv;
    if (toObject === true && projection instanceof Function) {
      rv[v] = rv[v][0];
    }
    return rv;
  }, {});
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppLayoutComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    NgReduxModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule,
    LayoutModule,
    CoreModule,
    LoginModule,
    UsersModule
  ],
  providers: [
    {
      provide: CustomAuthService,
      useFactory: (http: HttpClient) => {
        return customAuthServiceFactory(http, {
          loginUrl: `${environment.endpoint}/api/auth`,
          fetchUserUrl: `${environment.endpoint}/api/auth`,
          registerUrl: `${environment.endpoint}/api/auth/register`,
          logoutUrl: `${environment.endpoint}/api/auth`
        });
      },
      deps: [HttpClient]
    },
    {
      provide: SettingsService,
      useFactory: () => {
        return new USSDSettingsService();
      }
    },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [GenericFormComponent, GenericListComponent]
})
export class AppModule {
  @select((state: IAppState) => state.login.connected) $connected: Observable<DjammaUser>;
  constructor(private translate: TranslateService,
    private authGuard: AuthGuard,
    private updateUserGuard: UpdateUserGuard,
    private ngRedux: NgRedux<IAppState>,
    private authService: CustomAuthService,
    private router: Router,
    private redirectService: RedirectService,
    private rootService: RootService) {
    ngRedux.provideStore(store);
    redirectService.redirect401 = '/login'
    redirectService.redirect404 = '';
    redirectService.redirect500 = '';
    this.translate.setDefaultLang('fr');
    this.authGuard.fetchUser = this.fetchUser.bind(this);
    this.authGuard.accessChecker = (connected, requiredRoles) => {
      if(!connected) {
        return false;
      }
      console.log('requiredRoles', requiredRoles);
      const userRoles = formatRoles(connected.roles);
        console.log('userRoles', userRoles);
        let access = requiredRoles.length === 0;
        for (const r of requiredRoles) {
          access = access || userRoles.indexOf(`${r};`) >= 0;
        }
      return access;
    }
    this.updateUserGuard.fetchUser = this.authGuard.fetchUser;
    this.updateUserGuard.handleConnectedUser = (connected: DjammaUser) => {
      this.ngRedux.dispatch({
        type: SET_CONNECTED,
        payload: { connected: connected, auth: connected !== null }
      });
    };
    this.authService.handleConnected = (connected: DjammaUser) => {
      this.ngRedux.dispatch({
        type: SET_CONNECTED,
        payload: { connected: connected, auth: connected !== null }
      });
    };
    this.authService.handleLogout = (status) => {
      this.ngRedux.dispatch({
        type: LOGOUT
      });
      saveState({});
      this.router.navigate([redirectService.redirect401]);
    };
    this.authService.connectedProvider = () => {
      return this.$connected;
    };
    this.loadData();
  }

  loadData() {
    this.$connected.subscribe(user => {
      this.rootService.getDataByParams('/ussd-service/list', {'page': '-1', 'perPage' : '-1'}).subscribe(services => {
        this.ngRedux.dispatch({
            type: SET_SERVICES,
            payload: services
        });
      });
      this.rootService.getDataByParams('/ussd-service-item/list', {'page': '-1', 'perPage' : '-1'}).subscribe(serviceItems => {
        this.ngRedux.dispatch({
            type: SET_SERVICE_ITEMS,
            payload: serviceItems
        });
      });
      this.rootService.getDataByParams('/ussd-data-item/list', {'page': '-1', 'perPage' : '-1'}).subscribe(dataItems => {
        this.ngRedux.dispatch({
            type: SET_DATA_ITEMS,
            payload: dataItems
        });
      });

      this.rootService
        .getDataByParams('/role/list')
        .toPromise()
        .then((response: any) => {
          let roles = response || [];
          this.ngRedux.dispatch({
            type: SET_ROLES,
            payload: roles
          });
        })
        .catch(error => {
          console.log("Problem Roles", error);
        });

      this.rootService
        .getDataByParams('/profile/list')
        .toPromise()
        .then((response: any) => {
          let profiles = response || [];
          this.ngRedux.dispatch({
            type: SET_PROFILES,
            payload: profiles
          });
        })
        .catch(error => {
          console.log("Problem Profiles", error);
        });
    });
  }

  fetchUser() {
    return this.authService.fetchUser();
  }
}
