import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowlerModule } from './growler/growler.module';
import { GenericModule } from './generic/generic.module';
import { GridModule } from './grid/grid.module';
import { FormModule } from './form/form.module';
import { ActionsModule } from './actions/actions.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GrowlerModule,
    GenericModule,
    GridModule,
    FormModule,
    ActionsModule
  ]
})
export class CoreModule { }
