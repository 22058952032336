/**
 * Created by sissoko on 06/04/2018.
 */
import {Component, Input} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export const CELL = 'CELLS';

@Component({
  selector: 'checkbox',
  template: `
    <div class="checkbox c-checkbox mt0">
      <label>
        <input [disabled]="params.colDef.readonly ? true : null" type="checkbox" value="{{params.value}}"
               [checked]="params.value === true || params.value === 'true' ? true : null"/>
        <span class="fa fa-check"></span>
      </label>
    </div>
  `
})
export class CheckboxRenderer implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}

@Component({
  selector: 'item-add-remove',
  template: `
    <i *ngIf="isOn" class="remove icon icon-trash" (click)="remove()"></i>
    <i *ngIf="!isOn" class="add icon icon-plus" (click)="add()"></i>
  `,
  styles: [`
    .remove {
      cursor: pointer;
      color: red;
    }

    .add {
      cursor: pointer;
      color: green;
    }
  `
  ]
})
export class ItemAddRemoveComponent implements ICellRendererAngularComp {
  public isOn: boolean;
  public params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isOn = this.params.colDef.isOn;
    if(typeof this.params.colDef.getStatus === 'function') {
      this.isOn = this.params.colDef.getStatus(this.params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  remove() {
    const colDef = this.params.colDef;
    if (colDef.onRemove) {
      colDef.onRemove(this.params.data);
    }
  }

  add() {
    const colDef = this.params.colDef;
    if (colDef.onAdd) {
      colDef.onAdd(this.params.data);
    }
  }
}

@Component({
  selector: 'item-remove',
  template: `<i class="remove icon icon-trash" (click)="handleClick()"></i>`,
  styles: [`
    .remove {
      cursor: pointer;
      color: red;
    }
  `]
})
export class ItemRemoveComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  handleClick() {
    const colDef = this.params.colDef;
    if (colDef.onClick) {
      const ok = confirm(`Are you sure you what to remove this item[${this.params.data.id}]?`);
      if (ok) {
        colDef.onClick(this.params.data);
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

}

@Component({
  selector: 'button-menu-item',
  template: `<i class="menu-item {{icon}}" (click)="handleClick($event)"></i>`,
  styles: [`
    .menu-item {
      cursor: pointer;
    }
  `]
})
export class ButtonMenuComponent implements ICellRendererAngularComp {
  public params: any;
  icon: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    let icon = this.params.colDef.icon;
    if (typeof icon === 'function') {
      icon = icon(this.params);
    }
    this.icon = icon;
  }

  handleClick($event) {
    const colDef = this.params.colDef;
    let disabled = this.params.colDef.disabled;
    if (typeof disabled === 'function') {
      disabled = disabled(this.params);
    }
    if (disabled) {
      return;
    }
    if (colDef.onClick) {
      colDef.onClick(this.params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

}

@Component({
  selector: 'link-cell-item',
  template: `<a class="link-cell" href [routerLink]="link" [class.disabled]="disabled"><i
    class="{{icon}}"></i>{{value}}</a>`,
  styles: [`
    .link-cell {
      cursor: pointer;
    }

    .disabled {
      color: currentColor;
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }
  `]
})
export class LinkCellComponent implements ICellRendererAngularComp {
  public params: any;
  value: string;
  icon: string;
  link: string;
  disabled: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    let colDef = this.params.colDef;
    if(typeof colDef.display === 'function') {
      this.value = colDef.display(params);
    }
    let icon = colDef.icon;
    if (typeof icon === 'function') {
      icon = icon(this.params);
    }
    this.icon = icon;

    let link = colDef.link;
    if (typeof link === 'function') {
      link = link(this.params);
    }
    this.link = link;

    let disabled = colDef.disabled;
    if (typeof disabled === 'function') {
      disabled = disabled(this.params);
    }
    this.disabled = disabled;
  }

  refresh(params: any): boolean {
    return false;
  }
}

// {CheckboxRenderer, ItemAddRemoveComponent, ItemRemoveComponent, ButtonMenuComponent, LinkCellComponent}
