import {NgModule, ANALYZE_FOR_ENTRY_COMPONENTS} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridComponent} from './grid.component';
import {RelatedListComponent} from './related-list/related-list.component';
import {
  ButtonMenuComponent,
  CheckboxRenderer,
  ItemAddRemoveComponent,
  ItemRemoveComponent,
  LinkCellComponent
} from './cell/cell-renderer';
import {BooleanColumnFilter} from './filter/column-filter';
import {AgGridModule} from 'ag-grid-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { ActionsModule } from '../actions/actions.module';
import {PicklistEditor} from "./cell/cell-editor";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ActionsModule,
    {
      ngModule: AgGridModule,
      providers: [
        {
          provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: [
            CheckboxRenderer,
            PicklistEditor,
            ItemAddRemoveComponent,
            ItemRemoveComponent,
            ButtonMenuComponent,
            LinkCellComponent,
            BooleanColumnFilter
          ], multi: true
        }
      ],
    }
  ],
  declarations: [
    GridComponent,
    CheckboxRenderer,
    PicklistEditor,
    ItemAddRemoveComponent,
    ItemRemoveComponent,
    RelatedListComponent,
    ButtonMenuComponent,
    LinkCellComponent,
    BooleanColumnFilter
  ],
  exports: [
    GridComponent,
    CheckboxRenderer,
    PicklistEditor,
    ItemAddRemoveComponent,
    ItemRemoveComponent,
    RelatedListComponent,
    ButtonMenuComponent,
    LinkCellComponent,
    BooleanColumnFilter
  ]
})
export class GridModule {
}
